import { useState } from "react";
import axios from "../Authorization/Axios";
import { translateTextAPI } from "../Authorization/ServerPath";

export const useGetTranslation = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getTranslation = async (data, callback) => {
    setIsLoading(true);
    try {
      const response = await axios.get(translateTextAPI, data);

      if (typeof callback === "function") {
        callback(response.data?.output_content?.content);
      }

      setIsLoading(false);
      setData(response?.data);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  return { isLoading, data, error, getTranslation };
};
