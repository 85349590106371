import axios from "axios";
import Cookie from "js-cookie";

export const axiosInstanceWithAuth = axios.create();

axiosInstanceWithAuth.interceptors.request.use((config) => {
  const token = Cookie.get("userToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const axiosInstanceWithoutAuth = axios.create();
