import React, { useState } from "react";
// import axios from "../Authorization/Axios";
import { userDashboardAPI } from "../Authorization/ServerPath";
import { axiosInstanceWithAuth } from "../Authorization/AxiosConfig";

export const useGetUserDashboard = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getUserDashboard = async (path, onSuccess) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWithAuth.get(
        `${userDashboardAPI}/${path}`
      );
      setIsLoading(false);
      setData(response?.data);
      if (typeof onSuccess === "function") {
        onSuccess(response?.data);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  return { getUserDashboard, data, isLoading, error };
};
