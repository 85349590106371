import Styled from "styled-components";
import {
    largeDevice,
    mediumDevice,
    smallDevice,
    smallestDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledDivFlex = Styled.div `
${commonCSS}
   display: ${({ display }) => display || "flex"};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({ gap }) => gap};
  padding-top:${({ paddingTop }) => paddingTop};
  padding-bottom:${({ paddingBottom }) => paddingBottom};
  padding-right:${({ paddingRight }) => paddingRight};
  padding-left:${({ paddingLeft }) => paddingLeft};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  background: ${({ background }) => background};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  flex: ${({ flex }) => flex};
  opacity: ${({ opacity }) => opacity};
  border: ${({ border }) => border};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  overflow: ${({ overflow }) => overflow};
  background-image: ${({ backgroundImage }) => backgroundImage};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat};
  background-size: ${({ backgroundSize }) => backgroundSize};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  transform:${({ transform }) => transform}
  flex-wrap: ${({ flexWrap }) => flexWrap};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  cursor: ${({ cursor }) => cursor};
  border-top-right-radius: ${({ borderTRR }) => borderTRR};
  border-top-left-radius: ${({ borderTLR }) => borderTLR};
  border-bottom-right-radius: ${({ borderBRR }) => borderBRR};
  border-bottom-left-radius: ${({ borderBLR }) => borderBLR};
  border-top: ${({ borderTop }) => borderTop};
  transition: ${({ transition }) => transition};
  direction: ${({ direction }) => direction};
  /* animation: ${({ animation }) => (animation ? animation : "")} 1s; */
  ${({ customStyle }) => customStyle};

  ${largeDevice}
  ${mediumDevice}
  ${smallDevice}
  ${smallestDevice}
`;