import React, { useState } from "react";
import { theme } from "../../../../Theme";
import { StyledDivBox } from "../../../Common/Basics/DivBox";
import { StyledDivFlex } from "../../../Common/Basics/DivFlex";
import { StyledButton } from "../../../Common/Basics/StyledButton";
import { StyledHeader } from "../../../Common/Basics/StyledHeader";
import { StyledImage } from "../../../Common/Basics/StyledImage";
import { StyledInput } from "../../../Common/Basics/StyledInput";
import { StyledText } from "../../../Common/Basics/StyledText";
import Popup from "../../Popup/Popup";
import { StyledForm } from "../../../Common/Basics/StyledForm";
import googleIcon from "../../../../Images/googleicon.svg";
import { breakPoint } from "../../../../constant/breakPoint";
import { useUserLogin } from "../../../UsersAuth/hooks/useUserLogin";
import ButtonGroup from "../../../Common/ButtonGroup";
import {
  errorMessage,
  successMessage,
} from "../../../../utils/ResponseHandler";

const Login = ({ isOpen, onClose, onOpenSignUp }) => {
  const [userData, setUserData] = useState();

  const { data, isLoading, userLogin, error } = useUserLogin();

  const customId2 = "success";
  const message = "Registration successful";

  const style = {
    color: "red",
  };

  const customId1 = "error";

  const errMessage = (
    <>
      Login not successful:{" "}
      {error === "LOGIN_BAD_CREDENTIALS" ? (
        <span style={style}>Wrong email or password</span>
      ) : (
        error
      )}
    </>
  );

  const onSuccess = () => {
    successMessage(customId2, message);
  };

  const onError = () => errorMessage(customId1, errMessage);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    userLogin(userData, onError);
  };
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <StyledDivBox>
        <StyledHeader
          color={theme.colors.neutralColor1}
          Fs="4rem"
          FsS="3.4rem"
          textAlign="center"
          padding="1rem"
          FsSd="2.4rem"
          textAlignSd="start"
          paddingSd="0 4rem"
        >
          Building a better to financial freedom with AI.
        </StyledHeader>

        <StyledDivFlex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          padding="1rem"
        >
          <StyledDivBox
            width="50%"
            widthM="60%"
            widthS="80%"
            widthSd="100%"
            padding="2rem"
          >
            <StyledDivFlex padding=" 0 0 2rem 1rem">
              <StyledText
                Fs="2.4rem"
                color={theme.colors.neutralColor1}
                fontWeight="bold"
                FsSd="1.5rem"
              >
                LOGIN
              </StyledText>
            </StyledDivFlex>
            <StyledForm onSubmit={onSubmit}>
              {" "}
              <StyledDivFlex flexDirection="column" gap="2rem">
                <StyledInput
                  width="85%"
                  widthS="90%"
                  widthSd="100%"
                  name="username"
                  type="email"
                  value={userData?.email}
                  onChange={handleChange}
                  required
                  padding="1rem"
                  borderRadius="5px"
                  placeholderFs={window.innerWidth <= 750 ? "1.8rem" : "2rem"}
                  border="none"
                  Fs="2rem"
                  placeholder="Username"
                  placeholderFontWeight="bold"
                />

                <StyledInput
                  width="85%"
                  padding="1rem"
                  type="password"
                  required
                  widthS="90%"
                  widthSd="100%"
                  name="password"
                  value={userData?.password}
                  onChange={handleChange}
                  borderRadius="5px"
                  placeholderFs={window.innerWidth <= 750 ? "1.8rem" : "2rem"}
                  Fs="2rem"
                  border="none"
                  placeholder="Password"
                  placeholderFontWeight="bold"
                />

                <StyledDivFlex flexDirection="column" gap="1rem">
                  <ButtonGroup
                    width="85%"
                    padding="1rem"
                    widthS="90%"
                    widthSd="100%"
                    borderRadius="5px"
                    isLoading={isLoading}
                    Fs="2rem"
                    FsS="1.8rem"
                    color={theme.colors.primaryColor}
                    type="submit"
                  >
                    Submit
                  </ButtonGroup>
                  <StyledText
                    color={theme.colors.neutralColor1}
                    Fs="1.5rem"
                    padding="0 0 0 1.5rem"
                    textAlignSd="center"
                    // display="flex"
                    // flexWrap="wrap"
                  >
                    Trouble logging in? Email{" "}
                    {window.innerWidth <= 580 && <br />}
                    <a
                      href="mailto:tester@eyeandseek.ai"
                      style={{ color: "#fff" }}
                    >
                      info@eyeandseek.ai
                    </a>
                  </StyledText>
                </StyledDivFlex>

                <StyledHeader
                  Fs="2.4rem"
                  FsS="2rem"
                  fontFamily="bold"
                  color={theme.colors.neutralColor1}
                  padding="0 0 0 1rem"
                  FsSd="1.5rem"
                >
                  CREATE NEW ACCOUNT
                </StyledHeader>

                <StyledDivFlex
                  background={theme.colors.neutralColor1}
                  width="85%"
                  widthS="90%"
                  widthSd="100%"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="5px"
                >
                  <StyledImage
                    src={googleIcon}
                    alt="google icon"
                    width="24px"
                    height="24px"
                  />
                  <StyledButton
                    //   width="85%"
                    padding="1rem"
                    borderRadius="5px"
                    Fs="2rem"
                    FsS="1.8rem"
                    color={theme.colors.primaryColor}
                    background={theme.colors.neutralColor1}
                  >
                    Continue with Google
                  </StyledButton>
                </StyledDivFlex>

                <StyledButton
                  width="85%"
                  padding="1rem"
                  widthS="90%"
                  widthSd="100%"
                  borderRadius="5px"
                  onClick={() => {
                    onOpenSignUp();
                    onClose();
                  }}
                  Fs="2rem"
                  FsS="1.8rem"
                  color={theme.colors.primaryColor}
                >
                  Sign up with Email
                </StyledButton>
              </StyledDivFlex>
            </StyledForm>
          </StyledDivBox>
        </StyledDivFlex>
      </StyledDivBox>
    </Popup>
  );
};

export default Login;
