import { createGlobalStyle } from "styled-components";

export default createGlobalStyle `

 /* @font-face {
    font-family: "Druk Wide";
    src: url("./Fonts/DrukWideWebMediumRegular.ttf");
}  */



  *::after,
  *::before {
      margin:0;
      padding:0;
      box-sizing:inherit;
  }


  html{
      font-size:62.5%;
      
  }


body {
     font-family: 'Inter', sans-serif;
    box-sizing:border-box;
    color:#000000;
        /* overflow: hidden; */

    
   
  }




  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none
}

input[type=number] {
  -moz-appearance: textfield;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

`;