import styled, { css } from "styled-components";
import { breakPoint } from "./breakPoint";

const generateMediaQuery = (suffix, breakPoint) => {
  return css`
    @media (max-width: ${breakPoint}) {
      width: ${({ [`width${suffix}`]: value }) => value};
      height: ${({ [`height${suffix}`]: value }) => value};
      color: ${({ [`color${suffix}`]: value }) => value};
      display: ${({ [`display${suffix}`]: value }) => value};
      padding: ${({ [`padding${suffix}`]: value }) => value};
      padding-bottom: ${({ [`paddingBottom${suffix}`]: value }) => value};
      text-align: ${({ [`textAlign${suffix}`]: value }) => value};
      line-height: ${({ [`lineHeight${suffix}`]: value }) => value};
      /* background-image: url(${({ [`backgroundImage${suffix}`]: value }) =>
        value}); */
      margin: ${({ [`margin${suffix}`]: value }) => value};
      flex-direction: ${({ [`flexDirection${suffix}`]: value }) => value};
      align-items: ${({ [`alignItems${suffix}`]: value }) => value};
      position: ${({ [`position${suffix}`]: value }) => value};
      margin-top: ${({ [`marginTop${suffix}`]: value }) => value};
      box-shadow: ${({ [`boxShadow${suffix}`]: value }) => value};
      /* justify-content: ${({ [`flexDirection${suffix}`]: value }) =>
        value}; */
      min-width: ${({ [`width${suffix}`]: value }) => value};
      place-items: ${({ [`placeItems${suffix}`]: value }) => value};
      border-bottom: ${({ [`borderBottom${suffix}`]: value }) => value};
      font-size: ${({ [`Fs${suffix}`]: value }) => value};
      white-space: ${({ [`whiteSpace${suffix}`]: value }) => value};
      justify-content: ${({ [`justifyContent${suffix}`]: value }) => value};
      justify-items: ${({ [`justifyItems${suffix}`]: value }) => value};
      gap: ${({ [`gap${suffix}`]: value }) => value};
      top: ${({ [`Top${suffix}`]: value }) => value};
      right: ${({ [`Right${suffix}`]: value }) => value};
      left: ${({ [`Left${suffix}`]: value }) => value};
      bottom: ${({ [`Bottom${suffix}`]: value }) => value};

      grid-template-columns: ${({ [`gridTemplateColumns${suffix}`]: value }) =>
        value};

      overflow-x: ${({ [`overFlowX${suffix}`]: value }) => value};
      overflow: ${({ [`overFlow${suffix}`]: value }) => value};
      overflow-y: ${({ [`overFlowY${suffix}`]: value }) => value};
      background: ${({ [`background${suffix}`]: value }) => value};
    }
  `;
};

export const veryLargeDevice = generateMediaQuery(
  "V",
  breakPoint.VeryLargeScreen
);
export const largeDevice = generateMediaQuery("L", breakPoint.largeScreen);
export const mediumDevice = generateMediaQuery("M", breakPoint.mediumScreen);
export const smallDevice = generateMediaQuery("S", breakPoint.smallScreen);
export const smallestDevice = generateMediaQuery(
  "Sd",
  breakPoint.smallestScreen
);

export const extraSmallScreen = generateMediaQuery(
  "Es",
  breakPoint.extraSmallScreen
);
