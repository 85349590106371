import { createContext, useEffect, useState } from "react";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import MapUserToken from "../Authorization/MapUserToken";

export const getUserInfoContext = createContext();

const GetUserInfoProvider = ({ children }) => {
  const isLoggedIn = MapUserToken();
  const { isLoading, getUserInfo, error, data } = useGetUserInfo();
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo();
    }
  }, []);

  return (
    <getUserInfoContext.Provider
      value={{ data, isLoading, error, admin, setAdmin }}
    >
      {" "}
      {children}{" "}
    </getUserInfoContext.Provider>
  );
};

export default GetUserInfoProvider;
