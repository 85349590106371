import React, { useState, useEffect } from "react";

import { DateRangePicker } from "rsuite";
import styled from "styled-components";
import subDays from "date-fns/subDays";
import "rsuite/dist/rsuite.min.css";
import format from "date-fns/format";
import { commonCSS } from "../../../Components/Common/Basics/common";
import { useGetAdminDashboard } from "../hooks/useGetAdminDashboard";

const WeekPicker = ({
  setRefreshKey,
  setAdminData,
  setButtonClicked,
  setLoadingState,
}) => {
  const [date, setDate] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [yearWeekValue, setYearWeekValue] = useState({});
  const { data, isLoading, error, getAdminDashboard } = useGetAdminDashboard();
  // const [startDate, setStartDate] = useState(null);

  const formatDate = (date) => {
    return date?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  useEffect(() => {
    if (date) {
      const formattedDateRange = `${formatDate(date?.[0])} ~ ${formatDate(
        date?.[1]
      )}`;

      setSelectedWeek(formattedDateRange);
      const getYearAndWeekNumber = (dateString) => {
        const date = new Date(dateString);
        const yearStart = new Date(date.getFullYear(), 0, 1);
        const weekNumber = Math.ceil(
          ((date - yearStart) / 86400000 + yearStart.getDay() + 1) / 7
        );
        return { year: date.getFullYear(), weekNumber };
      };

      const [startDate] = formattedDateRange.split(" ~ ");
      const { year, weekNumber } = getYearAndWeekNumber(startDate);

      if (year && weekNumber) {
        setYearWeekValue({ year, weekly_number: weekNumber });
      }
    }
  }, [date]);

  const StyledDatePicker = styled(DateRangePicker)`
    ${commonCSS}

    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius};
    background-color: white;
    border: ${({ border }) => border};
    /* outline: none; */
    /* width: 100%; */

    & div {
      border-color: none;
    }

    & div[role="combobox"] {
      /* outline: none !important;
    border: none !important; */

      &:focus-within {
        outline: none !important;
        border: none !important;
      }
    }

    & input {
      outline: none !important;
      border: none !important;
    }
  `;

  useEffect(() => {
    setLoadingState(isLoading);
  }, [isLoading]);

  return (
    <StyledDatePicker
      className="field"
      oneTap
      showOneCalendar
      // style={{ minWidth: 150 }}
      width="25%"
      widthS="100%"
      hoverRange="week"
      ranges={[]}
      // onChange={(data) => setDate(data)}

      onChange={(data) => {
        if (!data) {
          setRefreshKey((prev) => prev + 1);
          setDate("");
          return;
        }
        setDate(data);
        // const formattedDate = data?.toISOString().slice(0, 10);

        getAdminDashboard(
          "weekly-filter",
          {
            year: yearWeekValue?.year?.toString(),
            week_number: yearWeekValue?.weekly_number?.toString(),
          },
          (data) => {
            setAdminData(data?.simplifications);
            setButtonClicked(true);
          }
        );
      }}
      value={date}
      // dateFormat="MM.yyyy"
      // showWeekNumbers
      // renderTitle={(date) => date.toLocaleString("default", { week: "long" })}
      // showMonthYearPicker
      placeholder="Select week"
    ></StyledDatePicker>
  );
};

export default WeekPicker;
