import React, { useEffect, useState, useMemo } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { StyledTable } from "../../../Components/Common/Basics/StyledTable";
import { ColumnFilter } from "../../../Table/ColumFilter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { StyledText } from "../../../Components/Common/Basics/StyledText";
import { StyledDivBox } from "../../../Components/Common/Basics/DivBox";
import PaginationControls from "../../../Components/Common/Pagination/PaginationControl";
// import moment from "moment";
import moment from "moment-timezone";

const UsersTable = ({ data, isLoading }) => {
  const [newFormattedData, setNewFormattedData] = useState([]);

  // useEffect(() => {
  //   if (data) {
  //     const newData = data.map((item) => {
  //       let newItem = { ...item }; // creating a copy of the item

  //       // Parse the date as UTC
  //       let date = moment.utc(item.date, "hh:mm A MMM Do YYYY");

  //       // Convert to Pacific Time
  //       newItem.date = date
  //         .clone()
  //         .tz("America/Los_Angeles")
  //         .format("MMM D, YYYY, h:mm A");

  //       return newItem;
  //     });

  //     setNewFormattedData(newData);
  //
  //   }
  // }, [data]);

  const COLUMN = [
    {
      Header: "User email",
      accessor: "user_email",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Date",
      accessor: "date",
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "Age",
      accessor: "age",
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "Gender",
      accessor: "sex",
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "Preferred Level",
      accessor: "preferred_level",
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "Input",
      accessor: "input",
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "Output",
      accessor: "output",
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "Content Class",
      accessor: "content_category",
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const columns = useMemo(() => COLUMN, []);
  // const newUserData = useMemo(() => data, [data]);
  // const newUserData = useMemo(() => (Array.isArray(data) ? data : []), [data]);
  const newUserData = useMemo(() => (Array.isArray(data) ? data : []), [data]);

  // const newUserData = useMemo(
  //   () => (Array.isArray(newFormattedData) ? newFormattedData : []),
  //   [newFormattedData]
  // );

  // const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
  //   useTable(
  //     {
  //       data: newUserData,
  //       columns: columns,
  //     },

  //     useFilters,
  //     useSortBy
  //   );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Use 'page' instead of 'page' for pagination.
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: newUserData,
      // initialState: { pageIndex: 0 },
      initialState: { pageIndex: 0, pageSize: 20 }, // Set page size here
    },
    useFilters,
    useSortBy,
    usePagination // Add usePagination hook.
  );

  return (
    <StyledDivBox
      //   boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      //   borderRadius="1rem"
      background="white"
      style={{ maxWidth: "100%", overflowX: " auto " }}
      padding="2rem 0rem"
    >
      {data?.length ? (
        <>
          {!isLoading ? (
            <>
              {" "}
              <StyledTable {...getTableProps()} width="100% !important">
                <thead>
                  {headerGroups?.map((headerGroup) => (
                    <tr {...headerGroup?.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map((column) => (
                        <th
                          {...column?.getHeaderProps(
                            column?.getSortByToggleProps()
                          )}
                        >
                          {column?.render("Header")}
                          {/* <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDownIcon fontSize="large" />
                        ) : (
                          <ArrowDropUpIcon fontSize="large" />
                        )
                      ) : (
                        ""
                      )}
                    </span> */}
                          <div className="input-filter">
                            {column?.canFilter
                              ? column?.render("Filter")
                              : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row?.getRowProps()}>
                        {row?.cells?.map((cell) => {
                          return (
                            <td {...cell?.getCellProps()}>
                              {cell?.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
              <PaginationControls
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageCount={pageCount}
              />
            </>
          ) : (
            "Loading...."
          )}
        </>
      ) : (
        <StyledText
          color="grey"
          textAlign="center"
          fontSize="2rem"
          paddingTop="1rem"
        >
          No Result found
        </StyledText>
      )}
    </StyledDivBox>
  );
};

export default UsersTable;
