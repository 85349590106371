import React, { useContext, useState } from "react";
import { Navbar } from "react-bootstrap";

import { StyledButton } from "./Common/Basics/StyledButton";
import MapUserToken from "../Authorization/MapUserToken";
import { Link, useNavigate, useParams } from "react-router-dom";

import cookie from "js-cookie";
import { StyledText } from "./Common/Basics/StyledText";
import { StyledDivFlex } from "./Common/Basics/DivFlex";
import { theme } from "../Theme";
import { StyledHeader } from "./Common/Basics/StyledHeader";
import { StyledImage } from "./Common/Basics/StyledImage";
import logo from "../Images/logo.png";
import Login from "./Layouts/ModalLayout/Modal/Login";
import SignUp from "./Layouts/ModalLayout/Modal/SignUp/SignUp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MobileMenu from "./Layouts/ModalLayout/Modal/MobileMenu/MobileMenu";
import Profile from "./Layouts/ModalLayout/Modal/Profile/Profile";
import { getUserInfoContext } from "../context/GetuserInfoContext";
const AppBar = ({ logged }) => {
  const isLoggedIn = MapUserToken();

  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const { data: userInfo } = useContext(getUserInfoContext);

  const { pathname } = window.location;

  const logOut = (event) => {
    event.preventDefault();
    cookie.remove("userToken");
    navigate("/");
    window.location.reload();
  };
  return (
    <StyledDivFlex
      padding="3rem 5rem 0rem 5rem"
      justifyContent="space-between"
      position="relative"
      gapM="1rem"
      backgroundS={theme.colors.primaryColor}
      alignItems="end"
      alignItemsS="center"
      paddingS="1rem 5rem"
      paddingSd="1rem 3rem"
    >
      {pathname === "/" ? (
        <>
          <StyledDivFlex gap="2rem" alignItems="end" alignItemsS="center">
            <Link to="/">
              <StyledImage src={logo} height="40px" width="172px" />
            </Link>
            <a
              href="https://www.eyeandseek.ai/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              {/* Your link content */}

              <StyledText
                Fs="1.8rem"
                color={theme.colors.primaryColor}
                paddingM="0 0 0.5rem 0"
                paddingS="0px"
                FsS="1.6rem"
                displaySd="none"
                cursor={`${isLoggedIn && "pointer"}`}
                fontWeight="bold"
                colorS={theme.colors.neutralColor1}
              >
                HOME
              </StyledText>
            </a>
          </StyledDivFlex>
          <StyledDivFlex gap="1.5rem">
            {" "}
            <StyledDivFlex
              onClick={(event) =>
                // !isLoggedIn && setShowLogin(true)

                {
                  if (isLoggedIn) {
                    logOut(event);
                  } else {
                    setShowLogin(true);
                  }
                }
              }
            >
              <StyledText
                Fs="1.8rem"
                fontWeight="bold"
                displayM="block"
                displaySd="block"
                colorS={theme.colors.neutralColor1}
                color={theme.colors.primaryColor}
                // onClick={isLoggedIn ? logOut : ""}
                cursor="pointer"
                FsS="1.6rem"
              >
                {isLoggedIn ? "LOGOUT" : "LOGIN"}
              </StyledText>
            </StyledDivFlex>
            {isMenuOpen && (
              <StyledDivFlex
                display="none"
                displayS="flex"
                cursor="pointer"
                onClick={() => {
                  setIsMenuOpen(false);
                  setShowMobileMenu(true);
                }}
              >
                <MenuIcon
                  style={{
                    color: `${theme.colors.neutralColor1}`,
                    fontSize: "25px",
                  }}
                />
              </StyledDivFlex>
            )}
          </StyledDivFlex>
        </>
      ) : (
        <>
          <StyledDivFlex gap="2rem" alignItems="end">
            <Link to="/">
              <StyledImage src={logo} height="40px" width="172px" />
            </Link>

            <StyledText
              Fs="1.8rem"
              color={theme.colors.tertiaryColor}
              paddingM="0 0 0.5rem 0"
              FsS="1.2rem"
              fontWeight="bold"
              displayM="none"
            >
              Eye&Seek Dashboard
            </StyledText>

            <Link to="/" style={{ textDecoration: "none" }}>
              {" "}
              <StyledText
                Fs="1.8rem"
                color={theme.colors.tertiaryColor}
                paddingM="0 0 0.5rem 0"
                FsS="1.2rem"
                cursor="pointer"
                fontWeight="bold"
                displayM="none"
              >
                Demo
              </StyledText>
            </Link>
          </StyledDivFlex>
          <StyledDivFlex flexDirection="column">
            <StyledText
              Fs="1.5rem"
              fontWeight="bold"
              displayS="none"
              // displaySd="block"
              color={theme.colors.tertiaryColor}
              cursor="pointer"
              FsS="1.5rem"
            >
              {userInfo?.email}
            </StyledText>

            <StyledDivFlex justifyContent="end" displayS="none">
              <StyledText
                Fs="1.6rem"
                fontWeight="bold"
                displayM="block"
                displaySd="block"
                // colorS={theme.colors.neutralColor1}
                color={theme.colors.neutralColor}
                // onClick={isLoggedIn ? logOut : ""}
                cursor="pointer"
                FsS="1.6rem"
                onClick={(event) =>
                  // !isLoggedIn && setShowLogin(true)

                  {
                    if (isLoggedIn) {
                      logOut(event);
                    }
                  }
                }
              >
                logout
              </StyledText>
            </StyledDivFlex>
          </StyledDivFlex>

          {isMenuOpen && (
            <StyledDivFlex
              display="none"
              displayS="flex"
              cursor="pointer"
              onClick={() => {
                setIsMenuOpen(false);
                setShowMobileMenu(true);
              }}
            >
              <MenuIcon
                style={{
                  color: `${theme.colors.neutralColor1}`,
                  fontSize: "25px",
                }}
              />
            </StyledDivFlex>
          )}
        </>
      )}

      <MobileMenu
        isOpen={showMobileMenu}
        onClose={() => {
          setShowMobileMenu(false);
          setIsMenuOpen(true);
        }}
        onOpenProfile={() => setShowProfile(true)}
      />

      <Login
        isOpen={showLogin}
        onClose={() => setShowLogin(false)}
        onOpenSignUp={() => setShowSignUp(true)}
      />

      <SignUp isOpen={showSignUp} onClose={() => setShowSignUp(false)} />

      <Profile
        isOpen={showProfile}
        onClose={() => {
          setShowProfile(false);
          setShowMobileMenu(false);
          setIsMenuOpen(true);
        }}
      />
    </StyledDivFlex>
  );
};

export default AppBar;
