import Styled from "styled-components";
import { commonCSS } from "./common";

export const StyledSelect = Styled.select`

${commonCSS}
  color:${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  



`;
