import { useState } from "react";
// import axios from "../Authorization/Axios";
import { translationFeedBackAPI } from "../Authorization/ServerPath";
import { axiosInstanceWithAuth } from "../Authorization/AxiosConfig";
// import { translateTextAPI } from "../Authorization/ServerPath";

export const useTranslationFeedback = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const translationFeedback = async (data, id, callback) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWithAuth.patch(
        `${translationFeedBackAPI}/${id}`,
        data
      );
      setIsLoading(false);
      if (typeof callback === "function") {
        callback();
      }

      setData(response);
    } catch (error) {
      setError(error.response);
    }
  };

  return { error, data, isLoading, translationFeedback };
};
