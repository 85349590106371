import styled from "styled-components";
import { theme } from "../../../Theme";
import { commonCSS } from "./common";

export const StyledSpinning = styled.div`
  ${commonCSS}

  display: flex;
  align-items: center;

  ::after {
    content: "";
    width: ${({ width }) => width || "2.5rem"};
    height: ${({ height }) => height || "2.5rem"};
    border: 10px solid #dddddd;
    border-top-color: ${theme.colors.primaryColor};
    border-bottom-color: ${theme.colors.primaryColor};
    border-radius: 50%;
    animation: loading ${({ speed }) => speed} linear infinite;
  }

  @keyframes loading {
    to {
      transform: rotate(1turn);
    }
  }
`;
