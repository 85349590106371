import thumbs_down from "./Images/thumbs_down.png";
import thumbs_up from "./Images/thumbs_up.png";
import great_emoji from "./Images/great_emoji.png";
import amazon from "./Images/amazon-logo.png";
import facebook from "./Images/Facebook-Logo.png";
import google from "./Images/google-logo.png";
import googleJigsaw from "./Images/google-jigsaw.png";
import android from "./Images/logo_android.png";
import netflix from "./Images/netflix-2-logo.png";
import sunny from "./Images/sunny_black.png";
import riot from "./Images/riot-games.png";
import oculus from "./Images/Logo_Oculus.png";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

export const feedBackData = [
  { label: "GOOD", rating: 1, icon: ThumbUpIcon },
  { label: "BAD", rating: 2, icon: ThumbDownIcon },

  // { label: "GREAT", rating: 3, icon: great_emoji },
];

export const contentCategories = [
  "Banking ",
  "Consumer Products",
  " Education",
  "Health",
  "Finance",
];

export const usersData = [
  {
    id: 1,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },

  {
    id: 2,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },

  {
    id: 3,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },

  {
    id: 4,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },
  {
    id: 5,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },
  {
    id: 6,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },

  {
    id: 7,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },

  {
    id: 8,
    UserEmail: "Intruder",
    Date: "23.7.22",
    Age: "22:25:50",
    MF: "img_intruder_001.jpg",
    PreferredLevel: "check them out",
    Input: "check them out",
    Output: "check them out",
    ContentClass: "check them out",
  },
];

export const companiesLogo = [
  { id: 1, logo: google },
  { id: 2, logo: riot },
  { id: 3, logo: amazon },
  { id: 4, logo: googleJigsaw },
  { id: 5, logo: facebook },
  { id: 6, logo: sunny },
  { id: 7, logo: android },
  { id: 8, logo: oculus },
  { id: 9, logo: netflix },
];

export const months = [
  { label: "Jan", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Apr", value: 4 },
  { label: "May", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Aug", value: 8 },
  { label: "Sep", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dec", value: 12 },
];
