import React, { useEffect, useState, useContext } from "react";
import { contentCategories } from "../../FeedBackData";
import { theme } from "../../Theme";
import { StyledDivBox } from "../Common/Basics/DivBox";
import { StyledDivFlex } from "../Common/Basics/DivFlex";
import { StyledText } from "../Common/Basics/StyledText";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import MapUserToken from "../../Authorization/MapUserToken";
import { useGetUserDashboard } from "../../hooks/useGetUserDashboard";
import { getUserInfoContext } from "../../context/GetuserInfoContext";
import { fontSize } from "@mui/system";

const UserDashboard = ({ onClose }) => {
  const { isLoading, getUserInfo, error, data } = useGetUserInfo();
  const { data: userInfo } = useContext(getUserInfoContext);

  const {
    isLoading: isUserDashboardLoading,
    getUserDashboard,
    error: userDashboardError,
    data: userDashboardData,
  } = useGetUserDashboard();

  const [preferredLevel, setPreferredLevel] = useState("");
  const [totalWords, setTotalWord] = useState("");
  const [contentCategory, setContentCategory] = useState([]);

  const preferred_level = "preferred_level";
  const top_content_categories = "top_content_categories";
  const total_words_simplified = "total_words_simplified";

  const isLoggedIn = MapUserToken();

  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    getUserDashboard(preferred_level, (data) => {
      setPreferredLevel(data);
    });

    getUserDashboard(top_content_categories, (data) => {
      setContentCategory(data);
    });

    getUserDashboard(total_words_simplified, (data) => {
      setTotalWord(data);
    });
  }, []);

  return (
    <StyledDivBox
      padding="6rem 3rem"
      width="100%"
      position="relative"
      onClick={(e) => e.stopPropagation()}
    >
      <StyledDivFlex justifyContent="space-between" alignItems="center">
        <StyledDivFlex flexDirection="column" gap="4rem">
          <StyledDivFlex flexDirection="column">
            <StyledText Fs="2.4rem" fontWeight="bold">
              Most Used Level
            </StyledText>
            <StyledText
              Fs="4rem "
              fontWeight="bold"
              color={theme.colors.primaryColor}
            >
              Level {preferredLevel?.preferred_grade}
            </StyledText>
          </StyledDivFlex>

          <StyledDivFlex flexDirection="column">
            <StyledText Fs="2.4rem" fontWeight="bold">
              Total Words Simplified
            </StyledText>
            <StyledText
              Fs="4rem "
              fontWeight="bold"
              color={theme.colors.primaryColor}
            >
              {totalWords?.total_words_count}
            </StyledText>
          </StyledDivFlex>

          <StyledDivFlex flexDirection="column">
            <StyledText Fs="2.4rem" fontWeight="bold">
              Top 5 Most Simplified Content Categories
            </StyledText>
            {contentCategory?.top_categories?.map((item) => (
              <StyledText
                Fs="4rem "
                fontWeight="bold"
                color={theme.colors.primaryColor}
              >
                {item}
              </StyledText>
            ))}
          </StyledDivFlex>
        </StyledDivFlex>

        <StyledDivFlex
          onClick={() => onClose()}
          cursor="pointer"
          position="absolute"
          right="4rem"
          top="6rem"
        >
          <StyledText>
            <CancelIcon style={{ color: "#C8C0E0", fontSize: "35px" }} />
          </StyledText>
        </StyledDivFlex>
      </StyledDivFlex>
      <StyledDivFlex position="absolute" top="1rem" right="2rem">
        <StyledText
          Fs="1.5rem"
          color={theme.colors.primaryColor}
          fontWeight="bold"
          paddingM="1.2rem"
          FsS="1.2rem"
          FsSd="1rem"
          // alignSelf="end"
        >
          {userInfo?.email}
        </StyledText>
      </StyledDivFlex>
    </StyledDivBox>
  );
};

export default UserDashboard;
