import React, { useState } from "react";
// import axios from "../../../Authorization/Axios";
import { adminDashboardAPI } from "../../../Authorization/ServerPath";
import { axiosInstanceWithAuth } from "../../../Authorization/AxiosConfig";

export const useGetAdminDashboard = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getAdminDashboard = async (path, data, onSuccess) => {
    setIsLoading(true);

    try {
      const response = await axiosInstanceWithAuth.post(
        `${adminDashboardAPI}/${path}`,
        data
      );
      setIsLoading(false);
      setData(response?.data);
      if (typeof onSuccess === "function") {
        onSuccess(response?.data);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  return { getAdminDashboard, data, isLoading, error };
};
