import styled from "styled-components";
import {
  largeDevice,
  mediumDevice,
  smallDevice,
  smallestDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledInput = styled.input`
  ${commonCSS}
  padding: ${({ padding }) => padding};
  border-radius: ${(borderRadius) => borderRadius};
  background-color: ${({ theme }) => theme};
  border: ${({ border }) => border};
  outline: none;
  font-size: ${({ fontSize }) => fontSize};
  transform: scale(${({ scale }) => scale});
  position: ${({ position }) => position};
  width: ${({ width }) => width};

  &:focus {
    outline: none;
    border: ${({ borderFocus }) => borderFocus};
  }

  ::placeholder {
    font-size: ${({ placeholderFs }) => placeholderFs || "1.2rem"};

    /* color: #3700f0; */
    /* font-family: Poppins; */
    line-height: 2.2rem;
    font-weight: ${({ placeholderFontWeight }) => placeholderFontWeight || 400};
  }

  ${largeDevice}
  ${mediumDevice}
  ${smallDevice}
  ${smallestDevice}
`;
