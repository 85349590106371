import React from "react";
import { theme } from "../../../Theme";
import { StyledDivBox } from "../Basics/DivBox";
import { StyledDivFlex } from "../Basics/DivFlex";
import { StyledText } from "../Basics/StyledText";

const SimplifiedList = ({ display, desktopDisplay }) => {
  return (
    <StyledDivBox displayS={display} display={desktopDisplay} paddingS="0 2rem">
      <StyledText
        fontWeight="bold"
        Fs="clamp(1.8rem, 2vw, 3rem)"
        color={theme.colors.primaryColor}
        FsM="2.5rem"
        lineHeight="4rem"
        marginTop="1.29rem"
        marginS="0px 0 0 0"
        FsSd="2.2rem"
        // padding="0 10rem 0 0"
        paddingSd="0px !important"
        paddingMd="0px !important"
        display="block"
        FsS="4rem"
      >
        Simplify financial content into easy-to-understand words.
      </StyledText>
      <StyledDivFlex
        flexDirection="column"
        margin="3.6rem 0 0 0"
        gap="1rem"
        width="95%"
        marginSd="2rem 0 0 0"
      >
        <StyledDivFlex gap="1rem" alignItems="center">
          <StyledDivFlex
            borderRadius="50%"
            height="1rem"
            width="1rem"
            minWidth="1rem"
            background={theme.colors.primaryColor}
          ></StyledDivFlex>
          <StyledText
            color={theme.colors.tertiaryColor}
            Fs="1.5rem"
            fontWeight="600"
          >
            Select your personal simplification level
          </StyledText>
        </StyledDivFlex>

        <StyledDivFlex gap="1rem">
          <StyledDivFlex
            borderRadius="50%"
            height="1rem"
            width="1rem"
            minWidth="1rem"
            background={theme.colors.primaryColor}
            margin="1rem 0 0 0"
          ></StyledDivFlex>
          <StyledText
            color={theme.colors.tertiaryColor}
            Fs="1.5rem"
            fontWeight="600"
          >
            In the first box, paste in a sentence or paragraph from any web page
            OR type a question
          </StyledText>
        </StyledDivFlex>

        <StyledDivFlex gap="1rem" alignItems="center">
          <StyledDivFlex
            borderRadius="50%"
            height="1rem"
            minWidth="1rem"
            width="1rem"
            background={theme.colors.primaryColor}
          ></StyledDivFlex>
          <StyledText
            color={theme.colors.tertiaryColor}
            Fs="1.5rem"
            fontWeight="600"
          >
            Click the SIMPLIFY button
          </StyledText>
        </StyledDivFlex>
      </StyledDivFlex>
    </StyledDivBox>
  );
};

export default SimplifiedList;
