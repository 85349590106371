import React, { useEffect, useState, useRef, useContext } from "react";
import Select, { components } from "react-select";
import { StyledDivBox } from "../../../Components/Common/Basics/DivBox";
import { StyledDivFlex } from "../../../Components/Common/Basics/DivFlex";
import { StyledButton } from "../../../Components/Common/Basics/StyledButton";
import { StyledInput } from "../../../Components/Common/Basics/StyledInput";
import { StyledText } from "../../../Components/Common/Basics/StyledText";
import Footer from "../../../Components/Footer";
import AppBar from "../../../Components/Navbar";
import { months, usersData } from "../../../FeedBackData";
import { theme } from "../../../Theme";
import SingleDatePicker1 from "../DatePicker/SingleDatePicker";
import SingleDatePicker from "../DatePicker/SingleDatePicker";
import WeekPicker from "../DatePicker/WeekPicker";
import UsersTable from "./UsersTable";
import MonthYearPicker from "../DatePicker/MonthYearPicker";
import { useGetAdminDashboard } from "../hooks/useGetAdminDashboard";
import { debounce } from "lodash";
import { getUserInfoContext } from "../../../context/GetuserInfoContext";
import { StyledForm } from "../../../Components/Common/Basics/StyledForm";

const AdminDashboard = () => {
  const [searchByEmail, setSearchByEmail] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const { data, isLoading, error, getAdminDashboard } = useGetAdminDashboard();
  const isMounted = useRef(false);
  const [adminData, setAdminData] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loadingState, setLoadingState] = useState();

  const { data: userInfo, admin } = useContext(getUserInfoContext);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getAdminDashboard("dashboard_details", { skip: 0, limit: 0 }, (data) => {
      if (isMounted.current) {
        setAdminData(data?.simplifications);
      }
    });

    setLoadingState(isLoading);
  }, [refreshKey, admin]);

  const handleChange = (e) => {
    setSearchByEmail(e?.target?.value);
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegEx.test(e.target.value));
  };
  const debounceOnchange = debounce(handleChange, 1000);

  // useEffect(() => {

  //   if (!searchByEmail) {
  //     setRefreshKey((prev) => prev + 1);
  //     return;
  //   }

  //   getAdminDashboard("search_by_email", { email: searchByEmail }, (data) => {
  //     setAdminData(data?.simplifications);
  //
  //   });
  // }, [searchByEmail]);

  return (
    <StyledDivBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
      className="no-scroll"
    >
      <AppBar />
      <StyledDivBox padding="4rem 5rem 0rem 5rem">
        <StyledDivFlex justifyContent="space-between" paddingS="0 0 2rem 0">
          <StyledText
            Fs="1.8rem"
            color={theme.colors.tertiaryColor}
            paddingM="0 0 0.5rem 0"
            FsS="2rem"
            cursor="pointer"
            fontWeight="bold"
            displayS="flex"
            display="none"
          >
            Eye&Seek Dashboard
          </StyledText>

          <StyledText
            Fs="1.5rem"
            fontWeight="bold"
            displayS="flex"
            // displaySd="block"
            color={theme.colors.tertiaryColor}
            // cursor="pointer"
            FsS="1.5rem"
            display="none"
          >
            {userInfo?.email}
          </StyledText>
        </StyledDivFlex>

        <StyledDivFlex
          gap="4rem"
          alignItems="center"
          borderBottom="1px solid #F4F2F2"
          flexDirectionS="column"
          flexDirectionM="column"
          padding="0 0 2rem 0"
          width="100%"
          alignItemsM="start"
        >
          <StyledDivFlex
            gap="4rem"
            alignItems="center"
            width="60%"
            widthM="100%"
            // flexDirectionM="column"
          >
            <StyledDivFlex
              width="100%"
              gap="2rem"
              alignItems="center"
              flexDirectionS="column"
            >
              <StyledText whiteSpace="nowrap">User Data Filter</StyledText>
              <StyledDivFlex gap="2rem" width="100%" flexDirectionS="column">
                <SingleDatePicker
                  setAdminData={setAdminData}
                  setRefreshKey={setRefreshKey}
                  adminData={adminData}
                  setButtonClicked={setButtonClicked}
                  setLoadingState={setLoadingState}
                />
                <WeekPicker
                  setAdminData={setAdminData}
                  setRefreshKey={setRefreshKey}
                  setButtonClicked={setButtonClicked}
                  setLoadingState={setLoadingState}
                />
                <MonthYearPicker
                  setAdminData={setAdminData}
                  setRefreshKey={setRefreshKey}
                  setButtonClicked={setButtonClicked}
                  setLoadingState={setLoadingState}
                />
                <StyledButton
                  padding="0.4rem 2rem"
                  paddingS="0.6rem 2rem"
                  color={buttonClicked ? "white" : "blue"}
                  background={buttonClicked ? theme.colors.primaryColor : ""}
                  width="25%"
                  borderRadius="5px"
                  textWrap="nowrap"
                  widthS="100%"
                  onClick={() => {
                    setRefreshKey((prev) => prev + 1);
                    setButtonClicked(false);
                  }}
                >
                  Show All
                </StyledButton>
              </StyledDivFlex>
            </StyledDivFlex>
          </StyledDivFlex>

          <StyledDivFlex
            gap="2rem"
            alignItems="center"
            width="40%"
            widthM="60%"
            widthS="100%"
            flexDirectionS="column"
          >
            <StyledText>Search user</StyledText>

            <StyledForm
              background="#F4F2F2"
              border="1px #d3d3d3 solid"
              width="58%"
              widthS="100%"
              alignItems="center"
              borderRadius="5px"
              // padding="0.3rem"
            >
              <StyledInput
                // onChange={(e) => setSearchByEmail(e?.target?.value)}
                onChange={handleChange}
                // borderRadius="5px"
                required
                value={searchByEmail}
                border="none"
                padding="0.8rem"
                paddingS="0.8rem"
                widthS="80%"
                width="80%"
                borderRadius="5px 0 0 5px"
                // background="red"
                placeholder="Justin@gmail.com"
                color={isValidEmail ? theme.colors.primaryColor : "red"}
              />
              <StyledButton
                width="20%"
                widthS="20%"
                background="blue"
                color="white"
                padding="0.8rem"
                border="none"
                outline="none"
                borderRadius="0 5px 5px 0"
                onClick={(e) => {
                  e.preventDefault();
                  if (!searchByEmail) {
                    setRefreshKey((prev) => prev + 1);

                    return;
                  }

                  getAdminDashboard(
                    "search_by_email",
                    { email: searchByEmail },
                    (data) => {
                      setAdminData(data?.simplifications);

                      setButtonClicked(true);
                    }
                  );
                }}
              >
                GO
              </StyledButton>
            </StyledForm>
          </StyledDivFlex>
        </StyledDivFlex>
        <UsersTable data={adminData} isLoading={loadingState} />
      </StyledDivBox>
      <Footer url=" https://www.eyeandseek.ai/" />
    </StyledDivBox>
  );
};

export default AdminDashboard;
