import React, { useState, useEffect } from "react";
import { DatePicker } from "rsuite";
import styled from "styled-components";
import { commonCSS } from "../../../Components/Common/Basics/common";
import { StyledDivBox } from "../../../Components/Common/Basics/DivBox";
import { useGetAdminDashboard } from "../hooks/useGetAdminDashboard";
const StyledDatePicker = styled(DatePicker)`
  ${commonCSS}

  padding: ${({ padding }) => padding};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: white;
  border: ${({ border }) => border};
  /* outline: none; */
  /* width: 100%; */

  & div {
    border-color: none;
  }

  & div[role="combobox"] {
    /* outline: none !important;
    border: none !important; */

    &:focus-within {
      outline: none !important;
      border: none !important;
    }
  }

  & input {
    outline: none !important;
    border: none !important;
    /* ::placeholder {
      font-size: 3vw;
    } */
  }
`;

const SingleDatePicker1 = ({
  setAdminData,
  adminData,
  setRefreshKey,
  setButtonClicked,
  setLoadingState,
}) => {
  const [date, setDate] = useState(adminData);
  const [startDate, setStartDate] = useState(null);
  const { data, isLoading, error, getAdminDashboard } = useGetAdminDashboard();

  useEffect(() => {
    setLoadingState(isLoading);
  }, [isLoading]);

  return (
    <StyledDatePicker
      oneTap
      // style={{ minWidth: 150 }}
      borderRadius="1rem"
      placeholder="Select Day"
      size="md"
      width="25%"
      widthS="100%"
      onChange={(data) => {
        if (!data) {
          setRefreshKey((prev) => prev + 1);
          return;
        }

        const formattedDate = data?.toISOString().slice(0, 10);

        getAdminDashboard("daily-filter", { date: formattedDate }, (data) => {
          setAdminData(data?.simplifications);
          setButtonClicked(true);
        });
      }}
    >
      {/* <DatePicker /> */}
    </StyledDatePicker>
  );
};

export default SingleDatePicker1;
