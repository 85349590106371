import React, { useEffect, useContext, useState } from "react";
import { contentCategories } from "../../../../../FeedBackData";
import { theme } from "../../../../../Theme";
import { StyledDivFlex } from "../../../../Common/Basics/DivFlex";
import { StyledText } from "../../../../Common/Basics/StyledText";
import Popup from "../../../Popup/Popup";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import MapUserToken from "../../../../../Authorization/MapUserToken";
import { getUserInfoContext } from "../../../../../context/GetuserInfoContext";
import { useGetUserDashboard } from "../../../../../hooks/useGetUserDashboard";

const Profile = ({ isOpen, onClose }) => {
  // const { isLoading, getUserInfo, error, data } = useGetUserInfo();
  const isLoggedIn = MapUserToken();
  const { data: userInfo } = useContext(getUserInfoContext);

  const {
    isLoading: isUserDashboardLoading,
    getUserDashboard,
    error: userDashboardError,
    data: userDashboardData,
  } = useGetUserDashboard();

  const [preferredLevel, setPreferredLevel] = useState("");
  const [totalWords, setTotalWord] = useState("");
  const [contentCategory, setContentCategory] = useState([]);

  const preferred_level = "preferred_level";
  const top_content_categories = "top_content_categories";
  const total_words_simplified = "total_words_simplified";

  useEffect(() => {
    if (isLoggedIn) {
      // getUserInfo();
    }
  }, []);

  useEffect(() => {
    getUserDashboard(preferred_level, (data) => {
      setPreferredLevel(data);
    });

    getUserDashboard(top_content_categories, (data) => {
      setContentCategory(data);
    });

    getUserDashboard(total_words_simplified, (data) => {
      setTotalWord(data);
    });
  }, []);

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      isDisclaimerFooter
      display="none"
      displaySd="flex"
      displayS="flex"
    >
      <StyledDivFlex
        flexDirection="column"
        gap="2rem"
        alignItems="center"
        justifyContent="center"
        // padding="0 0 10rem 0"
        // overFlow="scroll"
      >
        <StyledDivFlex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <StyledText
            color={theme.colors.neutralColor1}
            Fs="2.4rem"
            fontWeight="bold"
            FsEs="1.8rem"
            // textAlign="center"
          >
            Profile
          </StyledText>
          <StyledText
            color={theme.colors.secondaryTextColor}
            Fs="1.5rem"
            fontWeight="bold"
            FsEs="1.4rem"
            // textAlign="center"
          >
            {userInfo?.email}
          </StyledText>
        </StyledDivFlex>

        <StyledDivFlex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <StyledText
            color={theme.colors.neutralColor1}
            Fs="2.4rem"
            textAlign="center"
            fontWeight="bold"
            FsEs="1.8rem"
          >
            {/* Preferred <br /> Simplification Level */}
            Most Used Level
          </StyledText>
          <StyledText
            color={theme.colors.secondaryTextColor}
            Fs="4rem"
            fontWeight="bold"
            FsEs="2.5rem"
          >
            Level {preferredLevel?.preferred_grade}
          </StyledText>
        </StyledDivFlex>

        <StyledDivFlex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <StyledText
            color={theme.colors.neutralColor1}
            Fs="2.4rem"
            fontWeight="bold"
            FsEs="1.8rem"
          >
            Total Words Simplified
          </StyledText>
          <StyledText
            color={theme.colors.secondaryTextColor}
            Fs="4rem"
            fontWeight="bold"
            FsEs="2.4rem"
          >
            {totalWords?.total_words_count}
          </StyledText>
        </StyledDivFlex>

        <StyledDivFlex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <StyledText
            color={theme.colors.neutralColor1}
            Fs="2.4rem"
            fontWeight="bold"
            FsEs="1.8rem"
          >
            Top 5 Content Categories
          </StyledText>
          <StyledDivFlex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="0 0 2rem 0"
          >
            {contentCategory?.top_categories?.map((item, index) => (
              <StyledText
                color={theme.colors.secondaryTextColor}
                Fs="2rem"
                FsEs="1.8rem"
                fontWeight="bold"
                key={index}
              >
                {item}
              </StyledText>
            ))}
          </StyledDivFlex>
        </StyledDivFlex>
      </StyledDivFlex>
    </Popup>
  );
};

export default Profile;
