import { useState } from "react";
import axios from "../../../Authorization/Axios";
import {
  axiosInstanceWithAuth,
  axiosInstanceWithoutAuth,
} from "../../../Authorization/AxiosConfig";
import cookie from "js-cookie";
import { userLoginAPI } from "../../../Authorization/ServerPath";
import { useNavigate } from "react-router-dom";

export const useUserLogin = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const userLogin = async (data, onError) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);

    const config = {
      url: userLoginAPI,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        // "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axiosInstanceWithoutAuth(config);
      setData(response);

      setIsLoading(false);
      cookie.set("userToken", response?.data?.access_token, { expires: 1 });
      navigate("/");
      window.location.reload();
    } catch (error) {
      if (typeof onError === "function") {
        onError();
      }
      setIsLoading(false);
      setError(error?.response?.data?.detail);
    }
  };

  return { data, isLoading, error, userLogin };
};
