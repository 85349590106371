import { css } from "styled-components";
import {
  veryLargeDevice,
  largeDevice,
  mediumDevice,
  smallDevice,
  smallestDevice,
  extraSmallScreen,
} from "../../../constant/mediaQuery";

export const commonCSS = css`
  background-color: ${({ background }) => background};
   background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-size: ${({ backgroundSize }) => backgroundSize};
  background-position: ${({ bgPosition }) => bgPosition};
  background-attachment: ${({ backgroundAttachment }) => backgroundAttachment};
  object-fit: ${({ objectFit }) => objectFit};
  background-blend-mode: ${({ backgroundBlendMode }) => backgroundBlendMode};
  font-size: ${({ Fs }) => Fs};
  flex: ${({ flex }) => flex};
   text-overflow: ${({ textOverFlow }) => textOverFlow};
     white-space: ${({ whiteSpace }) => whiteSpace};
  color: ${({ color }) => color};
flex-wrap: ${({ flexWrap }) => flexWrap};
  place-items: ${({ placeItems }) => placeItems};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
    letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-decoration: ${({ textDecoration }) => textDecoration};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
   min-height: ${({ minHeight }) => minHeight};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  display: ${({ display }) => display};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-row-gap: ${({ gridRowGap }) => gridRowGap};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  cursor: ${({ cursor }) => cursor};
  gap: ${({ gap }) => gap};
  z-index: ${({ zIndex }) => zIndex};
  transform: ${({ transform }) => transform};
  flex-direction: ${({ flexDirection }) => flexDirection};
  box-shadow: ${({ boxShadow }) => boxShadow};
  align-self: ${({ alignSelf }) => alignSelf};
  line-height: ${({ lineHeight }) => lineHeight};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-top: ${({ borderTop }) => borderTop};
    overflow: ${({ overFlow }) => overFlow};
  overflow-y: ${({ overFlowY }) => overFlowY};
  overflow-x: ${({ overFlowX }) => overFlowX};
  opacity: ${({ opacity }) => opacity};
   direction:${({ direction }) => direction}
  scale: ${({ scale }) => scale};
  transition: ${({ transition }) => transition};
 border-top-right-radius: ${({ borderTRR }) => borderTRR};
  border-top-left-radius: ${({ borderTLR }) => borderTLR};
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-bottom-color: ${({ borderBottomColor }) => borderBottomColor};
  border-bottom-Style: ${({ borderBottomStyle }) => borderBottomStyle};
  border-bottom-right-radius: ${({ borderBRR }) => borderBRR};
  border-bottom-left-radius: ${({ borderBLR }) => borderBLR};
  border-top: ${({ borderTop }) => borderTop};



  :hover {
    background-color: ${({ hoverBg }) => hoverBg};
    color: ${({ hoverColor }) => hoverColor};
    scale: ${({ scaleOut }) => scaleOut};
    transform: ${({ transformOnHover }) => transformOnHover};
    z-index: ${({ zIndexOnHover }) => zIndexOnHover};
  }

  &::before {
    content: ${({ beforeContent }) => beforeContent || "kudos"};
    height: ${({ beforeHeight }) => beforeHeight};
    width: ${({ beforeWidth }) => beforeWidth};
    top: ${({ beforeTop }) => beforeTop};
    background-color: ${({ beforeBackground }) => beforeBackground};
    transform: ${({ beforeTransform }) => beforeTransform};
    position: ${({ beforePosition }) => beforePosition};
       /* color: rgba(0,0,0,0);  */
  }

  

   ${veryLargeDevice}
  ${largeDevice};
  ${mediumDevice};
  ${smallDevice};
  ${smallestDevice};
  ${extraSmallScreen}
  
`;
