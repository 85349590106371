import React, { useEffect, useState, useContext } from "react";
import Layout from "../../Components/Layout";

import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsArrowRightShort } from "react-icons/bs";
import { BiDislike, BiLike } from "react-icons/bi";
import { Link, Navigate } from "react-router-dom";
import Kudosaxios from "../../Authorization/Axios";
import { URL } from "../../config";
import MapUserToken from "../../Authorization/MapUserToken";
import { StyledDivFlex } from "../../Components/Common/Basics/DivFlex";
import { StyledImage } from "../../Components/Common/Basics/StyledImage";
import { StyledText } from "../../Components/Common/Basics/StyledText";
import bad_emoji from "../../Images/bad_emoji.png";
import good_emoji from "../../Images/good_emoji.png";
import great_emoji from "../../Images/great_emoji.png";
import { theme } from "../../Theme";
import { useNavigate } from "react-router-dom";
import { useTranslationFeedback } from "../../hooks/useTranslationFeedback";
import { checkLoginStatus } from "../../Authorization/UserAuthentication";
import { useGetTranslation } from "../../hooks/useGetTranslation";
import { feedBackData } from "../../FeedBackData";
import { StyledDivBox } from "../../Components/Common/Basics/DivBox";
import { StyledSpinning } from "../../Components/Common/Basics/Spinner";
import { StyledButton } from "../../Components/Common/Basics/StyledButton";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { translationDropDownData } from "../../TranslationDropDownData";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import GoogleAdsense from "../../Components/GoogleAdsense/GoogleAdsense";
import { StyledSelect } from "../../Components/Common/Basics/StyledSelect";
import Select, { components } from "react-select";
import { customSelectStyle, StyledSelectedDropDown } from "./style";
import TranslatedTextModal from "./TranslatedTextModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ModalLayout from "../../Components/Layouts/ModalLayout";
import UserDashboard from "../../Components/UserDashboard/UserDashboard";
import stats from "../../Images/stats.png";
import { getUserInfoContext } from "../../context/GetuserInfoContext";
import "./style.css";
import { StyledTextarea } from "../../Components/Common/Basics/StyledTextarea";
import SimplifiedList from "../../Components/Common/SimplifiedList/SimplifiedList";
import { usePostTextSimplification } from "../../hooks/usePostTextSimplification";
import ButtonGroup from "../../Components/Common/ButtonGroup";
import backgroundImage from "../../Images/background.jpg";

const Home = () => {
  const [tier, setTier] = useState("Tier 1 - Simplest");
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(16);
  const [logged, setLogged] = useState(false);
  const [translated, setTranslated] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [translatedText, setTranslatedText] = useState("");
  const [token, setToken] = useState(null);
  const [email, setEmial] = useState("");
  const [password, setPassowrd] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [authError, setAuthError] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const { data: userDetail, getUserInfo } = useGetUserInfo();
  const [showTranslatedModal, setShowTranslatedModal] = useState(false);
  const [showUserDashboard, setShowUserDashboard] = useState(false);
  const [inputWordCount, setInputWordCount] = useState(0);
  const [id, setId] = useState("");
  const [translatedWordCount, setTranslatedWordCount] = useState(0);
  const [btnText, setBtnText] = useState("SIMPLIFY");
  const [displayErrorMessage, setDisplayErrorMessage] = useState();

  const navigate = useNavigate();
  const {
    // data: translatedData,
    isLoading: isTranslatedLoading,
    error: translatedError,
    getTranslation,
  } = useGetTranslation();

  const {
    data: translatedData,
    error: postTranslatedError,
    postTextSimplification,
    isLoading: isPostTextLoading,
  } = usePostTextSimplification();

  const { isLoading, error, data, translationFeedback } =
    useTranslationFeedback();

  const userToken = MapUserToken();

  const isLoggedIn = checkLoginStatus();

  const [showFeedback, setShowFeedback] = useState(true);

  const customId2 = "feedback message";

  // const inputWordCount = text?.trim().split(/\s+/).length;

  useEffect(() => {
    const countWords = (str) => {
      return str.trim() === "" ? 0 : str.trim().split(/\s+/).length;
    };

    setTranslatedWordCount(countWords(translatedText));
  }, [translatedText]);

  useEffect(() => {
    Array.isArray(postTranslatedError)
      ? postTranslatedError.map(
          (error, index) => setDisplayErrorMessage(error.msg)
          // <p key={index}>{error.msg}</p>
        )
      : setDisplayErrorMessage(postTranslatedError);
  }, [postTranslatedError]);

  // show  input word count on paste event.

  const handlePaste = (event) => {
    const pasteText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    setText(text + pasteText);
  };

  const successToast = () => {
    toast.info("Thanks for your feedback", {
      toastId: customId2,
      className: "custom-toast",
      draggable: true,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const customDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon style={{ fontSize: "30px" }} />
    </components.DropdownIndicator>
  );

  const handleSelected = ({ value }) => {
    setSelectedValue(value);
  };

  const { data: userInfo, setAdmin } = useContext(getUserInfoContext);

  const translateText = async () => {
    // if (isLoggedIn !== null) {
    const onSuccessfulTranslated = (text) => {
      setTranslatedText(text);
      setTranslated(true);
      setShowFeedback(true);
      setId(translatedData?._id);
      setBtnText("CLEAR");

      // setShowTranslatedModal(true);
    };

    postTextSimplification(
      { content: text, grade: selectedValue },
      onSuccessfulTranslated
    );
    // }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F4F7FF",
      color: "white !important",
      fontSize: "2rem",
      fontWeight: "700",
      border: state.isFocused ? "none" : "none",
      // borderRadius: state.isFocused ? "6px 6px 0 0" : "6px",
      borderRadius:
        state.isFocused && state.isSelected === null ? "6px 6px 0 0" : "6px",

      // Removes the border when control is clicked (is in 'focus' state)
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        border: state.isFocused ? "none" : "none",
      },
      // Removes the border on hover
    }),

    menu: (provided, state) => ({
      ...provided,
      marginTop: "0px",
      boxShadow: "none",
      borderRadius: "0",
    }),

    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      borderRadius: "0",
    }),

    menuPortal: (provided) => ({
      ...provided,
      borderRadius: "0",
      // or 'none' if '0' doesn't work
    }),

    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px solid gray",
      backgroundColor: state.isSelected ? "blue" : "#F4F7FF",
      color: state.isSelected ? "#fff" : "blue",
      fontWeight: state.isSelected ? "700" : "700",
      fontSize: state.isSelected ? "2rem" : "2rem",
      borderRadius: "6px",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: isOpen ? "#AAA2C4" : "#fff",
    }),
  };

  return (
    <StyledDivBox
      position="relative"
      className="no-scroll"
      backgroundImage={backgroundImage}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundAttachment="fixed"
      backgroundBlendMode="screen"
      backgroundS="white"
      onClick={() => {
        if (showUserDashboard) {
          setShowUserDashboard(false);
        }
      }}
    >
      <Layout logged={logged} showBanner={showBanner} zIndex="1">
        {showUserDashboard ? (
          <UserDashboard onClose={() => setShowUserDashboard(false)} />
        ) : (
          <StyledDivBox width="100%">
            <StyledDivBox padding="1rem 2rem 2rem 2rem" positionM="relative">
              <StyledDivFlex
                justifyContent="end"
                flexDirection="column"
                gap="1rem"
                gapM="0.5rem"
              >
                <StyledText
                  Fs="1.5rem"
                  color={theme.colors.primaryColor}
                  fontWeight="bold"
                  paddingM="1.2rem"
                  FsS="1.2rem"
                  FsSd="1rem"
                  alignSelf="end"
                >
                  {/* {isLoggedIn && userDetail?.email} */}
                  {userInfo?.email}
                </StyledText>

                {userInfo?.is_superuser && (
                  <StyledButton
                    alignSelf="end"
                    color={theme.colors.neutralColor1}
                    background="#004AF0"
                    padding="0.5rem"
                    Fs="1.5rem"
                    borderRadius="5px"
                    onClick={() => {
                      navigate("/admin");
                      setAdmin(true);
                    }}
                  >
                    Admin
                  </StyledButton>
                )}
              </StyledDivFlex>
              {/* <div className="_box_row"> */}
              <StyledDivFlex
                alignItems="center"
                padding="1rem"
                justifyContent="space-between"
                gap="4rem"
                width="100%"
                flexDirectionM="column"
                gapM="2rem"
              >
                <StyledDivFlex
                  alignItems="center"
                  padding="1rem"
                  gap="3rem"
                  width="100%"
                  flexDirectionM="column"
                  gapM="2rem"
                  flex="1"
                >
                  <StyledText
                    Fs="2rem"
                    fontWeight="600"
                    marginS="0 6rem 0 0"
                    // width="40%"
                    whiteSpace="nowrap"
                    displayS="none"
                    color="gray"
                  >
                    Personalization Level
                  </StyledText>
                  <SimplifiedList display="block" desktopDisplay="none" />

                  <StyledDivFlex
                    width="55%"
                    widthM="100%"
                    customStyle={customSelectStyle}
                  >
                    <Select
                      className="react-select"
                      // classNamePrefix="my-select"
                      classNamePrefix={isOpen ? "" : "react-select"}
                      placeholder="Select Level"
                      options={translationDropDownData}
                      styles={customStyles}
                      components={{
                        DropdownIndicator: customDropdownIndicator,
                      }}
                      onChange={handleSelected}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "5px",
                        colors: {
                          ...theme.colors,
                          text: "red",
                          primary25: "hotpink",
                          primary: "black",
                        },
                      })}
                    />
                  </StyledDivFlex>
                </StyledDivFlex>

                {isLoggedIn && (
                  <StyledDivFlex
                    color="blue"
                    onClick={() => setShowUserDashboard(true)}
                    cursor="pointer"
                    positionM="absolute"
                    TopM="10rem"
                    RightM="2rem"
                    displayS="none"
                  >
                    <EqualizerIcon
                      style={{
                        color: `${theme.colors.primaryColor}`,
                        fontSize: "30px",
                      }}
                    />
                  </StyledDivFlex>
                )}
              </StyledDivFlex>
              {/* </div> */}

              <StyledDivBox
                display="grid"
                gridTemplateColumns="1fr 0.05fr 1fr"
                alignItems="center"
                gridTemplateColumnsM="1fr"
              >
                <StyledDivFlex
                  flexDirection="column"
                  alignItems="left"
                  paddingL="0 1rem"
                  paddingM="0 2rem"
                  position="relative"
                >
                  <StyledTextarea
                    // placeholder="Copy and paste here"
                    width="100%"
                    color="#646464"
                    height="38.1rem"
                    placeholderFs="3rem"
                    Fs="2rem"
                    fontWeight="600"
                    position="relative"
                    padding="2rem"
                    background="#F4F2F2"
                    value={text}
                    borderRadius="6px"
                    // onChange={(e) => {
                    //   const inputText = e.target.value;
                    //   setText(inputText);
                    //   const words =
                    //     text?.trim() === ""
                    //       ? 0
                    //       : text?.trim().split(/\s+/).length;
                    //   setInputWordCount(words);
                    // }}

                    onChange={(e) => {
                      const inputText = e.target.value;
                      setText(inputText);
                      const words =
                        inputText?.trim() === ""
                          ? 0
                          : inputText?.trim().split(/\s+/).length;
                      setInputWordCount(words);

                      if (btnText === "CLEAR") {
                        setBtnText("SIMPLIFY");
                      }
                    }}
                    // onPaste={handlePaste}
                    border="none"
                    resize="none"
                  ></StyledTextarea>

                  {!text.length && (
                    <StyledDivFlex
                      position="absolute"
                      top="17.5rem"
                      left="clamp(5rem,2vw,7rem)"
                      LeftSd="7rem"
                      LeftS="15rem"
                    >
                      <StyledText
                        Fs="clamp(1.8rem, 2vw, 2.5rem)"
                        color="#C8C0E0"
                        fontWeight="bold"
                        FsSd="3rem"
                        FsS="3rem"
                      >
                        Paste Text Here
                      </StyledText>
                    </StyledDivFlex>
                  )}
                  <StyledDivFlex height="4rem" alignItems="center">
                    <StyledText color="#AAA2C4" Fs="1.1rem">
                      {/* {text.length > 0 ? ( */}
                      Input Word Count: <span>{inputWordCount}</span>
                      {/* ) : ( */}
                      {/* "Input Word Count" */}
                      {/* )} */}
                    </StyledText>
                  </StyledDivFlex>

                  <StyledDivFlex
                    onClick={() => {
                      if (btnText === "SIMPLIFY" && text.length > 0) {
                        // Put your onClick logic for when text is NOT translated here
                        translateText();
                        setDisplayErrorMessage("");
                      } else {
                        // Put your onClick logic for when text IS translated here
                        setTranslated(false);
                        setText("");
                        setTranslatedText("");
                        setInputWordCount(0);
                        setBtnText("SIMPLIFY");
                        setDisplayErrorMessage("");
                      }
                    }}
                    background={text.length === 0 ? "#eaeaea" : "#004AF0"}
                    color={text.length === 0 ? "#9d9d9d" : "#fff"}
                    paddingRight="8.5rem"
                    paddingLeft="8.5rem"
                    paddingBottom="1rem"
                    margin=" 0 0 2rem 0"
                    widthM="100%"
                    display="none"
                    displayM="flex"
                    paddingTop="1rem"
                    borderRadius="3px"
                    cursor="pointer"
                    justifyContentM="center"
                    alignItemsM="center"
                    gap="1.5rem"
                  >
                    <ButtonGroup
                      outline="none"
                      Fs="2.4rem"
                      FsM="2rem"
                      isLoading={isPostTextLoading}
                      background={text.length === 0 ? "#eaeaea" : "#004AF0"}
                      color={text.length === 0 ? "#9d9d9d" : "white"}
                      disabled={text.length === 0 ? true : false}
                    >
                      {btnText}
                    </ButtonGroup>
                  </StyledDivFlex>
                </StyledDivFlex>

                <StyledDivFlex displayM="none">
                  <BsArrowRightShort
                    color={translatedText ? theme.colors.primaryColor : ""}
                    style={{ fontSize: 25 }}
                  />
                </StyledDivFlex>
                <StyledDivFlex
                  flexDirection="column"
                  alignItems="left"
                  height="100%"
                  paddingL="0 1rem"
                  paddingM="0 2rem"
                >
                  <StyledDivBox
                    background="#F4F7FF"
                    height="100%"
                    width="100%"
                    padding="2rem"
                    borderRadius="6px"
                    border="none"
                    position="relative"
                    heightL="38.1rem"
                  >
                    <StyledText
                      Fs="2rem"
                      color={theme.colors.primaryColor}
                      fontWeight="bold"
                    >
                      {/* {translatedText} */}
                      {translatedText.replace(/^"/, "")}
                    </StyledText>
                    <StyledText color="red">{displayErrorMessage}</StyledText>

                    {!translatedText && (
                      <StyledDivFlex
                        position="absolute"
                        top="17.5rem"
                        left="clamp(5rem,2vw,7rem)"
                        LeftSd="7rem"
                        LeftS="15rem"
                      >
                        <StyledText
                          Fs="clamp(1.8rem, 2vw, 2.5rem)"
                          color="#C8C0E0"
                          fontWeight="bold"
                          FsSd="3rem"
                          FsS="3rem"
                        >
                          Simplified Text
                        </StyledText>
                      </StyledDivFlex>
                    )}
                  </StyledDivBox>

                  <StyledDivFlex
                    justifyContent="space-between"
                    alignItems="center"
                    height="4rem"
                  >
                    <StyledText
                      color="#AAA2C4"
                      displayM="block "
                      Fs="1.1rem"
                      ali
                    >
                      Output Word Count{" "}
                      <b>
                        {/* {translatedData?.output_content?.length} */}
                        {translatedWordCount}
                      </b>
                    </StyledText>

                    {translated && showFeedback && (
                      <StyledDivFlex
                        alignSelf="start"
                        marginTop="1rem"
                        gap="1rem"
                      >
                        <StyledText
                          textAlign="center"
                          display="block"
                          height="4rem"
                          color="#AAA2C4"
                        >
                          Rate this <br /> translation{" "}
                        </StyledText>

                        {feedBackData.map((item, index) => (
                          <StyledDivFlex
                            key={index}
                            alignItems="center"
                            cursor="pointer"
                            gap="0.5rem"
                            onClick={() => {
                              translationFeedback(
                                {
                                  rating: item?.rating,
                                },
                                id,

                                id && text.length > 0 && successToast()
                              );

                              setShowFeedback(false);
                            }}
                          >
                            {/* <StyledImage
                            width="26.68px"
                            height="27.76px"
                            src={item.icon}
                            alt="feedback"
                            color="blue"
                          /> */}
                            {
                              <item.icon
                                style={
                                  translated
                                    ? {
                                        color: theme.colors.primaryColor,
                                        fontSize: "30px",
                                      }
                                    : { color: "#C8C0E0", fontSize: "30px" }
                                }
                              />
                            }
                          </StyledDivFlex>
                        ))}
                      </StyledDivFlex>
                    )}
                  </StyledDivFlex>
                </StyledDivFlex>
              </StyledDivBox>

              {/* translator boxes */}

              {/* changed isloggedIn to !isLoggedIn */}

              <StyledDivBox>
                <StyledDivFlex
                  flexDirection="row"
                  justifyContent="space-between"
                  margin="30px 0 0 0"
                  alignItems="center"
                >
                  <StyledDivFlex
                    background={text.length === 0 ? "#eaeaea" : "#004AF0"}
                    color={text.length === 0 ? "#9d9d9d" : "#fff"}
                    paddingRight="8.5rem"
                    paddingLeft="8.5rem"
                    paddingBottom="1rem"
                    alignItems="center"
                    justifyContent="center"
                    widthM="100%"
                    paddingTop="1rem"
                    borderRadius="3px"
                    displayM="none"
                    cursor="pointer"
                    justifyContentM="center"
                    alignItemsM="center"
                    gap="1.5rem"
                    width="40%"
                    onClick={() => {
                      if (btnText === "SIMPLIFY" && text?.length > 0) {
                        // Put your onClick logic for when text is NOT translated here
                        translateText();
                        setDisplayErrorMessage("");
                      } else {
                        // Put your onClick logic for when text IS translated here
                        setTranslated(false);
                        setText("");
                        setTranslatedText("");
                        setInputWordCount(0);
                        setBtnText("SIMPLIFY");
                        setDisplayErrorMessage("");
                      }
                    }}
                  >
                    <ButtonGroup
                      outline="none"
                      Fs="2.4rem"
                      FsM="2rem"
                      isLoading={isPostTextLoading}
                      background={text.length === 0 ? "#eaeaea" : "#004AF0"}
                      color={text.length === 0 ? "#9d9d9d" : "white"}
                      disabled={text.length === 0 ? true : false}
                    >
                      {btnText}
                    </ButtonGroup>
                  </StyledDivFlex>

                  {/* {showBanner && (
                    <div>
                      <GoogleAdsense />
                    </div>
                  )} */}
                </StyledDivFlex>
              </StyledDivBox>
            </StyledDivBox>
          </StyledDivBox>
        )}

        <ToastContainer draggable={false} transition={Zoom} autoClose={3000} />
      </Layout>
    </StyledDivBox>
  );
};

export default Home;
