import styled from "styled-components";
import { breakPoint } from "../../constant/breakPoint";
import { commonCSS } from "../Common/Basics/common";

export const StyledPolicyText = styled.span`
  ${commonCSS}
  display: ${({ display }) => display || "block !important"};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  font-size: ${({ fontSize }) => fontSize || "1.5rem"};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${breakPoint.smallestScreen}) {
    font-size: 1.2rem;
  }
`;

export const StyledPolicyHeader = styled.span`
  ${commonCSS}
  display: ${({ display }) => display || "block !important"};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop || "2.5rem"};
  font-size: ${({ fontSize }) => fontSize || "1.5rem"};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight || "bold"};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: ${breakPoint.smallestScreen}) {
    font-size: 1.2rem !important;
  }
`;
