import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRouteGuard, ProtectedRouteGuard } from "./Routes/Gaurd";
import { publicRoute, protectedRoute } from "./Routes/Routes";
import GlobalProvider from "./context/globalContext";
function App() {
  return (
    <GlobalProvider>
      {" "}
      <BrowserRouter>
        {/* <Helmet>
        <title>LivePupil</title>
        <meta name="description" content="LivePupil is a computer program that helps people better understand what they read." />
      </Helmet> */}

        <Routes>
          {publicRoute.map(({ path, component }, index) => (
            <Route
              path={path}
              element={<PublicRouteGuard>{component}</PublicRouteGuard>}
              exact={true}
              key={index}
            />
          ))}

          {protectedRoute.map(({ path, component }, index) => (
            <Route
              path={path}
              element={<ProtectedRouteGuard>{component}</ProtectedRouteGuard>}
              exact={true}
              key={index}
            />
          ))}
        </Routes>
        {/* <Router /> */}
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
