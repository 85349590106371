import { Container, Row, Col } from "react-bootstrap";
import AppBar from "./Navbar";
import Footer from "./Footer";

import { StyledText } from "./Common/Basics/StyledText";
import { theme } from "../Theme";
import { StyledDivBox } from "./Common/Basics/DivBox";
import DisplayCookies from "./DisplayCookies/DisplayCookies";
import { StyledDivFlex } from "./Common/Basics/DivFlex";
import { StyledList, StyledUl } from "./Common/Basics/list";
import { StyledHeader } from "./Common/Basics/StyledHeader";
import { StyledImage } from "./Common/Basics/StyledImage";
import SimplifiedList from "./Common/SimplifiedList/SimplifiedList";
import { companiesLogo } from "../FeedBackData";

const Layout = (props) => {
  return (
    <StyledDivFlex flexDirection="column" minHeight="100vh">
      <AppBar logged={props.logged} />
      <br />
      {/* <br /> */}

      <StyledDivFlex
        gap="5rem"
        flexDirectionS="column-reverse"
        gapSd="2rem"
        gapS="1.5rem"
      >
        <StyledDivBox
          // paddingSd="2rem !important"
          padding="0 1rem 0 10.2rem"
          paddingL="0 1rem 0 5.2rem"
          paddingS="0  6.5rem"
          // flex="1"
          width="36%"
          widthSd="100%"
          widthM="40%"
          widthS="95%"
          paddingSd="0 4rem 0 4rem"

          // widthS="100%"
        >
          <SimplifiedList display="none" />

          <StyledText
            Fs="2rem"
            color={theme.colors.primaryColor}
            // fontFamily="Open Sans"
            fontWeight="bold"
            display="block "
            marginTop="2rem"
            FsM="1.5rem"
            FsSd="2.2rem"
          >
            What we’re solving: The growing financial literacy gap in America.
            <StyledText
              Fs="1.3rem"
              color="#646464"
              fontWeight="500"
              display="block"
              marginTop="1rem"
              FsM="1.3rem"
              FsSd="1.4rem"
              fontStyle="Italic"
              displaySd="none"
            >
              54% of adults in America read below the 6th grade level. -{" "}
              <a
                href="https://www.thinkimpact.com/"
                style={{ color: "#3700F0" }}
              >
                ThinkImpact.com
              </a>
              . In today's economy, it's never been more important to have
              access to financial education and instantly apply that knowledge
              to make better financial decisions. We've identified major
              literacy issues in how financial institutions communicate their
              products, value, financial advice, and overall trust to their
              customers. Our AI-assisted simplification tool allows anyone to
              better understand financial content from anywhere. Imagine, an
              AI-assistant that can guide you to make better financial decisions
              from any device.
              {/* <a
                href="https://www.thinkimpact.com/literacy-statistics"
                style={{ color: "#3700F0" }}
              >
                https://www.thinkimpact.com/literacy-statistics
              </a> */}
            </StyledText>
            <StyledText
              Fs="1.3rem"
              color="#646464"
              fontWeight="500"
              marginTop="1rem"
              FsM="1.3rem"
              FsSd="1.4rem"
              fontStyle="Italic"
              display="none"
              displaySd="block"
            >
              <StyledDivFlex flexDirection="column" gap="1.5rem">
                {" "}
                <StyledText>
                  {" "}
                  Nationwide, on average, 79% of U.S. adults are literate in
                  2022.
                </StyledText>
                <StyledText>
                  54% of adults in America read below the 6th grade level.
                </StyledText>
                <StyledText>
                  Low levels of literacy costs the US up to 2.2 trillion per
                  year.
                </StyledText>
                <StyledText>
                  <a
                    href="https://www.thinkimpact.com/literacy-statistics"
                    style={{ color: "#646464" }}
                  >
                    https://www.thinkimpact.com/literacy-statistics
                  </a>
                </StyledText>
              </StyledDivFlex>
            </StyledText>
          </StyledText>

          <StyledDivFlex
            flexDirection="column"
            padding="4.4rem 0 0 0"
            gap="2rem"
          >
            <StyledText
              Fs="1.4rem"
              fontStyle="italic"
              fontWeight="700"
              color={theme.colors.primaryColor}
            >
              CREATED BY A WORLD-CLASS PRODUCT DESIGN TEAM
            </StyledText>
            <StyledDivFlex
              boxShadow="4px 9px 18px #00000029"
              borderRadius="1rem"
              width="80%"
              display="grid"
              gridTemplateColumns="repeat(3,1fr)"
              margin="0 0 3rem 0"
              gap="2rem"
              padding="2rem"
              paddingSd="2rem 0rem"
              widthSd="100%"
              boxShadowSd="none"
              boxShadowS="none"
              alignItemsS="center"
              justifyItemsS="center"
              widthS="100%"
              maxWidth="100%"
            >
              {companiesLogo.map(({ logo, id }) => (
                <StyledDivBox
                  width="80%"
                  margin="0 auto"
                  key={id}
                  padding="2rem"
                  backgroundImage={logo}
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                ></StyledDivBox>
              ))}
            </StyledDivFlex>
          </StyledDivFlex>
        </StyledDivBox>

        <StyledDivBox
          padding="0 9.4rem 0 0"
          // flex="1"
          width="60%"
          widthL="65%"
          paddingM="0 3rem 0 0"
          widthM="55%"
          widthS="100%"
          widthSd="100%"
          paddingS="0  5.2rem"
          paddingSd="0 0rem"
        >
          <StyledDivFlex
            flexDirection="column"
            boxShadow="4px 9px 18px #00000029"
            borderRadius="1rem"
            boxShadowS="none"
            background="white"
          >
            <StyledDivFlex
              flexDirectionM="column"
              gapM="0rem"
              justifyContent="space-between"
              displayS="none"
              borderBottomWidth="2px"
              borderBottomColor="#f7f7f7"
              borderBottomStyle="solid"
              alignItems="center"
              padding="0px 10px"
              height="30px"
              Fs="1.2rem"
            >
              <StyledText
                whiteSpace="nowrap"
                color="#AAA2C4"
                fontWeight="300"
                fontStyle="italic"
              >
                ALPHA 1.1
              </StyledText>
              <StyledText
                color="#AAA2C4"
                Fs="1rem"
                FsSd="0.7rem"
                fontWeight="300"
              >
                Disclaimer: Eye & Seek uses GPT3.5 and ChatGPT to create results
                using generative artificial intelligence.
              </StyledText>
            </StyledDivFlex>
            <StyledDivFlex
              display="flex"
              flex="1"
              minHeight="40%"
              // padding="0 4rem 0 0"
            >
              {props.children}
            </StyledDivFlex>
          </StyledDivFlex>
          <StyledDivFlex
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="3rem"
            displaySd="none"
            displayS="none"
          >
            <StyledText>
              For inquiries or feedback{" "}
              <a href="mailto: info@eyeandseek.ai" style={{ color: "#3700F0" }}>
                info@eyeandseek.ai
              </a>
            </StyledText>

            {/* <StyledText padding="0 0rem 0 0">
              Trouble logging in? Email{" "}
              <a
                href="mailto: tester@eyeandseek.ai"
                style={{ color: "#3700F0" }}
              >
                tester@eyeandseek.ai
              </a>
            </StyledText> */}
          </StyledDivFlex>
        </StyledDivBox>
      </StyledDivFlex>
      {/* footer */}
      {/* <DisplayCookies /> */}
      <Footer url="https://www.eyeandseek.ai/" />
    </StyledDivFlex>
  );
};

export default Layout;
