import React from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { breakPoint } from "../Constants/BreakPoints";
// import { smallestDevice } from "../Constants/MediaQuery/MediaQuery";

const styles = {
  "@media (max-width: 568px)": {
    top: "40em",
  },
};

const successMessage = (toastId, message, callback) => {
  toast.info(message, {
    toastId: { toastId },
    className: "custom-toast",
    draggable: true,
    position: toast.POSITION.TOP_RIGHT,
  });

  if (callback && typeof callback === "function") {
    callback();
  }
};

const errorMessage = (toastId, message, callback) => {
  toast.error(message, {
    toastId: { toastId },
    className: "custom-toast",
    draggable: true,
    position: toast.POSITION.TOP_RIGHT,
  });

  if (callback && typeof callback === "function") {
    callback();
  }
};

export { successMessage, errorMessage, ToastContainer, Zoom, styles };
