import styled from "styled-components";
import {
  largeDevice,
  mediumDevice,
  smallDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledTextarea = styled.textarea`
  ${commonCSS}

  resize: ${({ resize }) => resize};

  ::placeholder {
    color: #c8c0e0;
    /* font-size: clamp(1.8rem, 2vw, 2.5rem); */
    font-size: ${({ placeholderFs }) => placeholderFs};
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: 768px) {
      &::placeholder {
        font-size: 1.2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: red;
      }
    }

    @media only screen and (max-width: 480px) {
      &::placeholder {
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: red;
      }
    }
  }

  &:focus {
    outline: none;
  }

  ${largeDevice}
  ${mediumDevice}
${smallDevice}
`;
