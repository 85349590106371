import React, { createContext, useState } from "react";
import GetUserInfoProvider from "./GetuserInfoContext";

export const globalContext = createContext();

const GlobalProvider = ({ children }) => {
  return (
    <globalContext.Provider value={{}}>
      <GetUserInfoProvider> {children} </GetUserInfoProvider>{" "}
    </globalContext.Provider>
  );
};

export default GlobalProvider;
