import React from "react";
import { theme } from "../../../Theme";
import { StyledDivFlex } from "../Basics/DivFlex";
import { StyledSpinning } from "../Basics/Spinner";
import { StyledButton } from "../Basics/StyledButton";
import { StyledText } from "../Basics/StyledText";

const ButtonGroup = ({
  isLoading,
  width,
  spinnerWidth,
  spinnerHeight,
  background,
  padding,
  margin,
  borderRadius,
  Fs,
  color = theme.colors.primaryColor,
  fontWeight,
  onClickMessage,
  disabled,
  children,
  paddingS,
  FsS,
  widthL,
  widthSd,
  height,
  onClick,
}) => {
  return (
    <StyledButton
      padding={padding}
      margin={margin}
      borderRadius={borderRadius}
      fontSize={Fs}
      width={width}
      paddingS={paddingS}
      fontSizeS={FsS}
      background={background}
      fontWeight={fontWeight}
      onClick={onClickMessage}
      disabled={disabled}
      height={height}
      widthL={widthL}
      widthSd={widthSd}
    >
      <StyledDivFlex justifyContent="center" width="100%" gap="2rem">
        {isLoading && (
          <StyledSpinning
            speed="1s"
            width={spinnerWidth}
            height={spinnerHeight}
          />
        )}
        <StyledText color={color} whiteSpace="nowrap">
          {children}
        </StyledText>
      </StyledDivFlex>
    </StyledButton>
  );
};

export default ButtonGroup;
