import cookie from "js-cookie";
import jwt_decode from "jwt-decode";

export const checkLoginStatus = () => {
  const accessToken = cookie.get("userToken");

  if (!accessToken) return false;

  try {
    return jwt_decode(accessToken);
  } catch (error) {}
};
