import { css } from "styled-components";
import { breakPoint } from "../../constant/breakPoint";

export const customSelectStyle = css`
  & .react-select {
    width: 60% !important;

    @media (max-width: ${breakPoint.mediumScreen}) {
      width: 100% !important;
    }

    @media (max-width: ${breakPoint.largeScreen}) {
      width: 100% !important;
    }
  }
  /* .custom-select-option > div > div {
    background-color: red;
    color: white;
  } */
`;
