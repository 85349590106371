import React, { useState, useEffect } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import styled from "styled-components";
import "./MonthYearPicker.css";
import { commonCSS } from "../../../Components/Common/Basics/common";
import { useGetAdminDashboard } from "../hooks/useGetAdminDashboard";
import { set } from "date-fns";

const MonthYearPicker = ({
  setAdminData,
  adminData,
  setRefreshKey,
  setButtonClicked,
  setLoadingState,
}) => {
  const [value, setValue] = useState(null);
  const [extractedMonthYear, setExtractedMonthYear] = useState({});
  const { data, isLoading, error, getAdminDashboard } = useGetAdminDashboard();

  const renderCalendar = (calendar) => {
    // Hide the date rows and keep the month and year selection
    return React.cloneElement(calendar, {
      className: "custom-calendar",
      rowClassName: (date) => {
        if (date.getDate() > 1) {
          return "hidden";
        }
        return "";
      },
    });
  };

  useEffect(() => {
    setLoadingState(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (value) {
      const year = value.getFullYear();
      const month = value.getMonth() + 1; // getMonth() returns 0-11, so we need to add 1 to get the correct month number.

      if (year && month) {
        setExtractedMonthYear({ year, month });
      }
    }
  }, [value]);

  const StyledDatePicker = styled(DatePicker)`
    ${commonCSS}

    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius};
    background-color: white;
    border: ${({ border }) => border};
    /* outline: none; */
    /* width: 100%; */

    & div {
      border-color: none;
    }

    & div[role="combobox"] {
      /* outline: none !important;
    border: none !important; */

      &:focus-within {
        outline: none !important;
        border: none !important;
      }
    }

    & input {
      outline: none !important;
      border: none !important;
    }
  `;

  return (
    <StyledDatePicker
      // width="10%"
      format="MMM yyyy"
      value={value}
      width="25%"
      widthS="100%"
      onChange={(data) => {
        if (!data) {
          setRefreshKey((prev) => prev + 1);
          setValue(null);
          return;
        }
        setValue(data);
        const formattedDate = data?.toISOString().slice(0, 10);

        const year = data.getFullYear();
        const month = data.getMonth() + 1;
        getAdminDashboard(
          "monthly-filter",
          {
            year: year?.toString(),
            month: month?.toString(),
          },
          (data) => {
            setAdminData(data?.simplifications);
            setButtonClicked(true);
          }
        );
      }}
      renderCalendar={renderCalendar}
      placeholder="Select Month"
      className="month-year-picker custom-calender" // Add the className prop here
    />
  );
};

export default MonthYearPicker;
