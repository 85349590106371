import { useState } from "react";
// import axios from "../Authorization/Axios"
import axios from "axios";
import { axiosInstanceWithAuth } from "../Authorization/AxiosConfig";

import { translateTextAPI } from "../Authorization/ServerPath";

export const usePostTextSimplification = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const postTextSimplification = async (data, onSuccessfulTranslated) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWithAuth.post(translateTextAPI, data);

      setIsLoading(false);
      setData(response.data);
      if (typeof onSuccessfulTranslated === "function") {
        onSuccessfulTranslated(response.data?.output_content?.content);
      }
    } catch (error) {
      setIsLoading(false);

      setError(error?.response?.data?.detail);
    }
  };

  return { data, error, isLoading, postTextSimplification };
};
