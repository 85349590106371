export const breakPoint = {
  VeryLargeScreen: "2560px",

  largeScreen: "1196px",

  mediumScreen: "1003px",

  smallScreen: "750px",

  smallestScreen: "580px",

  extraSmallScreen: "400px",
};
