import React from "react";
import { StyledDivBox } from "../../../../Common/Basics/DivBox";
import { StyledDivFlex } from "../../../../Common/Basics/DivFlex";
import { StyledList } from "../../../../Common/Basics/list";
import Popup from "../../../Popup/Popup";
import { StyledUl } from "../../../../Common/Basics/list";
import { theme } from "../../../../../Theme";
import { Link } from "react-router-dom";
import MapUserToken from "../../../../../Authorization/MapUserToken";

const MobileMenu = ({ isOpen, onClose, onOpenSignUp, onOpenProfile }) => {
  const isLoggedIn = MapUserToken();
  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      isDisclaimerFooter
      display="none"
      displaySd="flex"
      displayS="flex"
    >
      <StyledDivFlex padding="5rem 0 0 0">
        <StyledUl
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          display="flex"
          gap="1rem"
          color={theme.colors.neutralColor1}
          Fs="2.4rem"
          cursor="pointer"
        >
          {isLoggedIn && (
            <StyledList onClick={() => onOpenProfile()}>PROFILE</StyledList>
          )}

          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <StyledList>DEMO</StyledList>
          </Link>

          <Link
            to="https://www.eyeandseek.ai/about"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledList>ABOUT</StyledList>
          </Link>

          <Link
            to="https://www.eyeandseek.ai/terms-and-policies"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledList>Terms & Policies</StyledList>
          </Link>

          {/* <StyledList>Logout</StyledList> */}
        </StyledUl>
      </StyledDivFlex>
    </Popup>
  );
};

export default MobileMenu;
