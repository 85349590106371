export const theme = {
  colors: {
    primaryColor: "#3700F0",
    secondaryColor: "#F0F0F0",
    tertiaryColor: "#000000",
    neutralColor: "#DBDBDB",
    neutralColor1: "#FFF",
    secondaryTextColor: "#AAA2C4",
  },
};
