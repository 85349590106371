import styled from "styled-components";
import {
  largeDevice,
  mediumDevice,
  smallDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledImage = styled.img`
  ${commonCSS}
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};

  ${largeDevice}
  ${mediumDevice}
${smallDevice}
`;
