import { useState } from "react";
import axios from "../../../Authorization/Axios";
import { axiosInstanceWithoutAuth } from "../../../Authorization/AxiosConfig";
import cookie from "js-cookie";
import { userRegistrationAPI } from "../../../Authorization/ServerPath";
// import { userLoginAPI } from "../../../Authorization/ServerPath";
import { useNavigate } from "react-router-dom";

export const useUserRegister = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const userRegister = async (data, onsuccess, onError) => {
    setIsLoading(true);
    try {
      // const response = await axios.post(userRegistrationAPI, data);
      const response = await axiosInstanceWithoutAuth.post(
        userRegistrationAPI,
        data
      );

      setData(response);
      setIsLoading(false);
      navigate("/");
      window.location.reload();

      if (typeof onsuccess === "function") {
        onsuccess();
      }
    } catch (error) {
      setIsLoading(false);

      setError(error?.response?.data?.detail);
      if (typeof onError === "function") {
        onError();
      }
    }
  };

  return { data, isLoading, error, userRegister };
};
