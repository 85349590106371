import React from "react";
import { StyledDivFlex } from "../Basics/DivFlex";
import { StyledButton } from "../Basics/StyledButton";
import { StyledText } from "../Basics/StyledText";

const PaginationControls = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageIndex,
  pageOptions,
  pageCount,
}) => (
  <StyledDivFlex
    justifyContent="center"
    alignItems="center"
    padding="3rem 10px"
  >
    <StyledButton
      margin="0 10px"
      background={canPreviousPage ? "#007BFF" : "#6c757d"}
      color="white"
      border="none"
      borderRadius="4px"
      padding="10px"
      paddingS="6px"
      cursor={canPreviousPage ? "pointer" : "default"}
      onClick={() => gotoPage(0)}
      disabled={!canPreviousPage}
    >
      {"<<"}
    </StyledButton>
    <StyledButton
      margin="0 10px"
      background={canPreviousPage ? "#007BFF" : "#6c757d"}
      color="white"
      border="none"
      borderRadius="4px"
      padding="10px"
      paddingS="6px"
      cursor={canPreviousPage ? "pointer" : "default"}
      onClick={() => previousPage()}
      disabled={!canPreviousPage}
    >
      {"<"}
    </StyledButton>
    <StyledText margin="0 10px" Fs="1.2em" FsS="1rem">
      Page{" "}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{" "}
    </StyledText>
    <StyledButton
      margin="0 10px"
      background={canNextPage ? "#007BFF" : "#6c757d"}
      color="white"
      border="none"
      borderRadius="4px"
      padding="10px"
      paddingS="6px"
      cursor={canNextPage ? "pointer" : "default"}
      onClick={() => nextPage()}
      disabled={!canNextPage}
    >
      {">"}
    </StyledButton>
    <StyledButton
      margin="0 10px"
      background={canNextPage ? "#007BFF" : "#6c757d"}
      color="white"
      border="none"
      borderRadius="4px"
      padding="10px"
      paddingS="6px"
      cursor={canNextPage ? "pointer" : "default"}
      onClick={() => gotoPage(pageCount - 1)}
      disabled={!canNextPage}
    >
      {">>"}
    </StyledButton>
  </StyledDivFlex>
);

export default PaginationControls;
