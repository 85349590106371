import React from "react";
import { StyledDivFlex } from "../Basics/DivFlex";
import { StyledText } from "../Basics/StyledText";

const Disclaimer = () => {
  return (
    <StyledDivFlex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginTop="auto"
      marginTopSd="0px"
      padding="0 4.5rem 2rem 4.5rem "
      // position="fixed"
      bottom="0"
    >
      <StyledText Fs="1.3rem" color="#C8C0E0">
        ALPHA 1.1
      </StyledText>
      <StyledText Fs="1.3rem" color="#C8C0E0">
        Disclaimer: Eye & Seek uses GPT3.5 and ChatGPT to create results using
        generative artificial intelligence.
      </StyledText>
    </StyledDivFlex>
  );
};

export default Disclaimer;
