import React, { useEffect } from "react";
import { theme } from "../../../Theme";
import { StyledDivBox } from "../../Common/Basics/DivBox";
import { StyledDivFlex } from "../../Common/Basics/DivFlex";
import { StyledImage } from "../../Common/Basics/StyledImage";
import { StyledText } from "../../Common/Basics/StyledText";
import Eye from "../../../Images/Eye.png";
import Footer from "../../Footer";
import CloseIcon from "@mui/icons-material/Close";
import Disclaimer from "../../Common/Disclaimer/Disclaimer";

const Popup = ({
  justifyContent = "center",
  isOpen,
  onClose,
  isDisclaimerFooter,
  children,
  display = "flex",
  displayS,
  displaySd,
}) => {
  // useEffect(() => {
  //   let remove_scroll = document.getElementById("no-scroll")?.style;
  //   if (isOpen) {
  //     if (remove_scroll) {
  //       remove_scroll.overflow = "hidden";
  //       remove_scroll.height = "100vh";
  //     }
  //   } else {
  //     if (remove_scroll) {
  //       remove_scroll.overflow = "";
  //       remove_scroll.height = "";
  //     }
  //   }
  // }, [isOpen]);

  useEffect(() => {
    let elements = document.getElementsByClassName("no-scroll");
    for (let i = 0; i < elements.length; i++) {
      if (isOpen) {
        elements[i].style.overflow = "hidden";
        elements[i].style.height = "100vh";
      } else {
        elements[i].style.overflow = "";
        elements[i].style.height = "";
      }
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <StyledDivBox
          background={theme.colors.primaryColor}
          position="fixed"
          //   justifyContent={justifyContent}
          //   alignItems={alignItems}
          display={display}
          displayS={displayS}
          displaySd={displaySd}
          flexDirection="column"
          top="0"
          bottom="0"
          right="0"
          left="0"
          overFlow="auto"
          justifyContent="space-between"
          zIndex="1000"
          minHeight="calc(100vh - 100px)"
          width="100vw"
          opacity={isOpen ? 1 : 0}
        >
          <StyledDivBox>
            {" "}
            <StyledDivFlex justifyContent="space-between" padding="4rem">
              <StyledDivFlex
                gap="1rem"
                alignItems="center"
                onClick={() => onClose()}
                cursor="pointer"
              >
                <StyledImage
                  src={Eye}
                  alt="login logo"
                  width="35px"
                  height="35px"
                />
                <StyledText
                  color={theme.colors.neutralColor}
                  fontWeight="bold"
                  Fs="1.8rem"
                >
                  EYE & SEEK
                </StyledText>
              </StyledDivFlex>
              <StyledDivFlex onClick={() => onClose()} cursor="pointer">
                <CloseIcon
                  style={{
                    color: `${theme.colors.neutralColor}`,
                    fontSize: "25px",
                  }}
                />
              </StyledDivFlex>
            </StyledDivFlex>
            <StyledDivFlex
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              {children}
            </StyledDivFlex>
          </StyledDivBox>

          {isDisclaimerFooter ? <Disclaimer /> : <Footer onClose={onClose} />}
        </StyledDivBox>
      )}
    </>
  );
};

export default Popup;
