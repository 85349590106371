import Home from "../Pages/Home";

import AdminDashboard from "../Pages/Admin/AdminDashboard/AdminDashboard";

export const publicRoute = [
  {
    path: "/",
    component: <Home />,
  },
];

export const protectedRoute = [
  {
    path: "/",
    component: <Home />,
  },

  {
    path: "/admin",
    component: <AdminDashboard />,
  },
];
