import React, { useState } from "react";
import { theme } from "../../../../../Theme";
import { StyledDivBox } from "../../../../Common/Basics/DivBox";
import { StyledDivFlex } from "../../../../Common/Basics/DivFlex";
import { StyledButton } from "../../../../Common/Basics/StyledButton";
import { StyledHeader } from "../../../../Common/Basics/StyledHeader";
import { StyledImage } from "../../../../Common/Basics/StyledImage";
import { StyledInput } from "../../../../Common/Basics/StyledInput";
import { StyledText } from "../../../../Common/Basics/StyledText";
import Popup from "../../../Popup/Popup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Select, { components } from "react-select";
import { useUserRegister } from "../../../../UsersAuth/hooks/useUserRegister";
import {
  successMessage,
  errorMessage,
  ToastContainer,
  Zoom,
} from "../../../../../utils/ResponseHandler";

// import "./style.css";
import "./styleSelect.css";
import { StyledForm } from "../../../../Common/Basics/StyledForm";
import ButtonGroup from "../../../../Common/ButtonGroup";
const SignUp = ({ isOpen, onClose }) => {
  const [checkIsOpen, setCheckIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userData, setUserData] = useState();
  const { data, error, isLoading, userRegister } = useUserRegister();
  const handleMenuOpen = () => {
    setCheckIsOpen(true);
  };

  const handleMenuClose = () => {
    setCheckIsOpen(false);
  };

  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const handleSelected = (option) => {
    // setSelectedValue(value);
    setSelectedOption(option);
  };

  const customDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon style={{ fontSize: "33px" }} />
    </components.DropdownIndicator>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff !important",
      color: "blue !important",
      fontSize: "2rem !important",
      fontWeight: "700",
      border: "none",
    }),

    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid gray",
      backgroundColor: state.isSelected ? "blue" : "#F4F7FF",
      color: state.isSelected ? "#fff" : "black",
      fontWeight: state.isSelected ? "700" : "500",
      fontSize: state.isSelected ? "2rem" : "2rem",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: checkIsOpen ? "blue" : "blue",
      fontSize: "1.7rem !important",
    }),
  };

  // Toaster Messages
  const customId2 = "success";
  const message = "Registration successful, click login ";

  const customId1 = "error";
  const errMessage = "Registration not successful";

  const onSuccess = () => {
    successMessage(customId2, message);
  };

  const onError = () => errorMessage(customId1, errMessage);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const genderValue = selectedOption?.value;
    const data = { ...userData, gender: genderValue };
    userRegister(data, onSuccess, onError);
  };
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <StyledDivBox>
        <StyledHeader
          color={theme.colors.neutralColor1}
          Fs="4rem"
          FsS="3.4rem"
          textAlign="center"
          padding="1rem"
          FsSd="2.4rem"
          textAlignSd="start"
          paddingSd="0 4rem"
        >
          Building a better to financial freedom with AI.
        </StyledHeader>

        <StyledDivFlex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          padding="1rem"
        >
          <StyledDivBox
            width="50%"
            padding="2rem"
            widthM="60%"
            widthS="80%"
            widthSd="100%"
          >
            <StyledDivFlex padding=" 0 0 2rem 1rem">
              <StyledText
                Fs="2.4rem"
                color={theme.colors.neutralColor1}
                fontWeight="bold"
                FsS="2rem"
                FsSd="1.5rem"
              >
                CREATE NEW ACCOUNT
              </StyledText>
            </StyledDivFlex>
            <StyledForm onSubmit={onSubmit}>
              <StyledDivFlex flexDirection="column" gap="2rem">
                <StyledInput
                  width="85%"
                  widthSd="100%"
                  required
                  padding="1rem"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={userData?.email}
                  borderRadius="5px"
                  placeholderFs={window.innerWidth <= 750 ? "1.8rem" : "2rem"}
                  border="none"
                  Fs="2rem"
                  placeholder="Username"
                  placeholderFontWeight="bold"
                />

                <StyledInput
                  width="85%"
                  widthSd="100%"
                  padding="1rem"
                  type="password"
                  required
                  borderRadius="5px"
                  placeholderFs={window.innerWidth <= 750 ? "1.8rem" : "2rem"}
                  Fs="2rem"
                  name="password"
                  onChange={handleChange}
                  value={userData?.password}
                  border="none"
                  placeholder="Password"
                  placeholderFontWeight="bold"
                />
                <StyledText color="yellow">
                  {error === "REGISTER_USER_ALREADY_EXISTS"
                    ? "User already existing"
                    : error}
                </StyledText>
                <StyledDivBox>
                  <StyledText color="#C8C0E0" Fs="1.5rem">
                    Additional Info
                  </StyledText>
                  <StyledDivFlex
                    alignItems="center"
                    gap="1rem"
                    width="85%"
                    widthSd="100%"
                  >
                    <StyledDivFlex width="60%" widthSd="65%">
                      <Select
                        className="select-dropdown"
                        // classNamePrefix="my-select"
                        classNamePrefix={checkIsOpen ? "" : "select-dropdown"}
                        placeholder="Select Gender"
                        options={options}
                        required
                        name="gender"
                        styles={customStyles}
                        components={{
                          DropdownIndicator: customDropdownIndicator,
                        }}
                        value={selectedOption}
                        onChange={handleSelected}
                        onMenuOpen={handleMenuOpen}
                        onMenuClose={handleMenuClose}
                      />
                      <StyledInput
                        type="text"
                        name="hiddenInput"
                        value={selectedOption ? selectedOption.value : ""}
                        required
                        formNoValidate
                        onChange={() => {}}
                        style={{
                          position: "absolute",
                          // left: "-9999px",
                          opacity: 0,
                        }}
                      />
                    </StyledDivFlex>

                    <StyledDivFlex width="40%" widthSd="32%">
                      <StyledInput
                        width="100%"
                        padding="1rem"
                        borderRadius="5px"
                        placeholderFs="2rem"
                        required
                        type="number"
                        name="age"
                        value={userData?.age}
                        onChange={handleChange}
                        Fs="2rem"
                        border="none"
                        placeholder="Age"
                        placeholderFontWeight="bold"
                      />
                    </StyledDivFlex>
                  </StyledDivFlex>
                </StyledDivBox>

                <StyledDivFlex
                  flexDirection="column"
                  gap="1rem"
                  padding="3rem 0 0 0"
                >
                  <ButtonGroup
                    width="85%"
                    padding="1rem"
                    widthSd="100%"
                    borderRadius="5px"
                    widthS="90%"
                    FsS="1.8rem"
                    isLoading={isLoading}
                    type="submit"
                    Fs="2rem"
                    color={theme.colors.primaryColor}
                  >
                    Submit
                  </ButtonGroup>
                  <StyledText
                    color={theme.colors.neutralColor1}
                    Fs="1.5rem"
                    padding="0 0 0 1.5rem"
                    textAlignSd="center"
                  >
                    Trouble logging in? Email{" "}
                    {window.innerWidth <= 580 && <br />}
                    <a
                      href="mailto:tester@eyeandseek.ai"
                      style={{ color: "#fff" }}
                    >
                      tester@eyeandseek.ai
                    </a>
                  </StyledText>
                </StyledDivFlex>
              </StyledDivFlex>
            </StyledForm>
          </StyledDivBox>
        </StyledDivFlex>
        {/* <ToastContainer Zoom /> */}
      </StyledDivBox>
    </Popup>
  );
};

export default SignUp;
