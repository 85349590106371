import styled from "styled-components";
import {
    largeDevice,
    mediumDevice,
    smallDevice,
    smallestDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledText = styled.span `
  ${commonCSS}
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  justify-self: ${({ justifySelf }) => justifySelf};
  align-self: ${({ alignSelf }) => alignSelf};
  position: ${({ position }) => position};
  bottom: ${({ Bottom }) => Bottom};
  left: ${({ Left }) => Left};
  right: ${({ Right }) => Right};
  font-family: ${({ fontFamily }) => fontFamily};
  top: ${({ Top }) => Top};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  white-space: ${({ whiteSpace }) => whiteSpace};
  cursor: ${({ cursor }) => cursor};
  display: ${({ display }) => display};
  text-align: ${({ textAlign }) => textAlign};
  background-color: ${({ background }) => background};
  font-weight: ${({ fontWeight }) => fontWeight};
  overflow: ${({ overFlow }) => overFlow};
  text-transform: ${({ textTransform }) => textTransform};
  text-overflow: ${({ textOverFlow }) => textOverFlow};
  font-style: ${({ fontStyle }) => fontStyle};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-decoration: ${({ textDecoration }) => textDecoration};

  ${largeDevice}
  ${mediumDevice}
  ${smallDevice}
  ${smallestDevice}
`;