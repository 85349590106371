import styled from "styled-components";
import {
  largeDevice,
  mediumDevice,
  smallDevice,
  smallestDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledList = styled.li`
  ${commonCSS}
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${(lineHeight) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  border: ${({ border }) => border};
  background-color: ${({ background }) => background};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  min-width: ${({ minWidth }) => minWidth};
  overflow: ${({ overFlow }) => overFlow};
  text-overflow: ${({ textOverFlow }) => textOverFlow};
  white-space: ${({ whiteSpace }) => whiteSpace};
  width: ${({ width }) => width};
  text-transform: ${({ textTransform }) => textTransform};
  border-radius: ${({ borderRadius }) => borderRadius};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  border-top-left-radius: ${({ topLeftBorderRadius }) => topLeftBorderRadius};
  border-bottom-left-radius: ${({ bottomLeftBorderRadius }) =>
    bottomLeftBorderRadius};

  /* &:hover {
    background-color: ${({ theme, noHover }) =>
    !noHover && theme.colors.secondaryColor};
    color: ${({ theme, hoverColor }) =>
    hoverColor || theme.colors.primaryColor};
  } */

  &::before {
    display: ${({ displayList }) => displayList};
    background-color: ${({ backgroundList }) => backgroundList};
    content: ${({ contentList }) => contentList};
    border-radius: ${({ borderRadiusList }) => borderRadiusList};
    width: ${({ widthList }) => widthList};
    height: ${({ heightList }) => heightList};
    vertical-align: ${({ verticalAlignList }) => verticalAlignList};
    margin: ${({ marginList }) => marginList};
  }

  ${largeDevice}
  ${mediumDevice}
  ${smallDevice}
  ${smallestDevice}
`;

export const StyledUl = styled.ul`
  ${commonCSS}

  padding: ${({ padding }) => padding || 0};
  margin: 0;
  position: ${({ position }) => position};
  background: ${({ background }) => background};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  border-radius: ${({ borderRadius }) => borderRadius};
  right: ${({ right }) => right};

  opacity: ${({ opacity }) => opacity};
  left: ${({ left }) => left};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: ${({ overFlow }) => overFlow};
  min-width: ${({ minWidth }) => minWidth};
  list-style-type: ${({ listStyle }) => listStyle || "none"};
  /* z-index: ${({ zIndex }) => 10 || zIndex}; */
  transform: scale(${({ scale }) => scale});

  ${largeDevice}
  ${mediumDevice}
  ${smallDevice}
  ${smallestDevice}
`;
