import { useState } from "react";
import { theme } from "../Theme";
import { StyledDivFlex } from "./Common/Basics/DivFlex";
import { StyledHeader } from "./Common/Basics/StyledHeader";
import { StyledText } from "./Common/Basics/StyledText";

import AboutUs from "./Layouts/ModalLayout/Modal/AboutUs";
import PrivacyPolicy from "./Layouts/ModalLayout/Modal/PrivacyPolicy";
import Eye from "../Images/Eye.png";
import { StyledImage } from "./Common/Basics/StyledImage";
import { StyledDivBox } from "./Common/Basics/DivBox";
import { Link } from "react-router-dom";

const Footer = ({ url, onClose }) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);

  return (
    <StyledDivFlex
      marginTop="auto"
      marginTopSd="0px"
      background="#3700F0"
      flexDirectionSd="column"
      justifyContent="space-between"
      flexDirection="column"
      // alignItems="center"
      padding="3.5rem  4rem "
      flexDirectionS="column"
      alignItemsS="start"
      gapSd="3rem"
      paddingBottomSd="8rem"
      // height="140px"
    >
      <StyledDivFlex
        alignItems="center"
        justifyContent="space-between"
        flexDirectionS="column"
        flexDirectionM="column"
        alignItemsM="start"
        gapS="2rem"
        alignItemsS="start"
        gapM="1rem"
      >
        <StyledDivFlex
          gap="2rem"
          alignItems="center"
          flexDirectionS="column"
          flexDirectionM="column"
          alignItemsM="start"
          gapS="1rem"
          gapM="1rem"
          alignItemsS="start"
          gapSd="0rem"
        >
          <StyledDivFlex
            alignItems="center"
            gap="0.5rem"

            // positionSd="absolute"
            // LeftSd="2.5rem"
            // BottomSd="25.5rem"
            // paddingSd="0 0 1.5rem 0"
          >
            <StyledDivFlex onClick={onClose} cursor="pointer">
              <Link to={url}>
                {" "}
                <StyledImage
                  src={Eye}
                  alt="login logo"
                  width="50px"
                  height="50px"
                />
              </Link>
            </StyledDivFlex>

            <StyledHeader Fs="1.8rem" color="#fff" fontWeight="800">
              EYE & SEEK
            </StyledHeader>
          </StyledDivFlex>

          <StyledText
            padding="1rem 0 0 0"
            color="#AAA2C4"
            fontSize="1.5rem"
            paddingS="0px"
            margin="0px "
            display="none"
            displaySd="flex"
          >
            <p>Made in Los Angeles, CA</p>
          </StyledText>

          <StyledText
            // padding="1rem 0 0 0"
            color="#AAA2C4"
            fontSize="1.5rem"
            paddingS="0px"
          >
            <p>
              &#169; 2023. Eye and Seek, LLC. All rights reserved. Patent
              Pending.
            </p>
          </StyledText>

          <StyledText
            // padding="1rem 0 0 0"
            color="#AAA2C4"
            fontSize="1.5rem"
            paddingS="0px"
            margin="0px !important"
            displaySd="none"
          >
            <p>Made in Los Angeles, CA</p>
          </StyledText>
        </StyledDivFlex>
        <StyledDivFlex flexDirectionSd="column" gapSd="1rem" alignSelfS="start">
          <StyledText
            color="#fff"
            fontSize="1.5rem"
            fontWeight="bold"
            display="none"
            displaySd="flex"
            FsSd="1.5rem"
          >
            ABOUT
          </StyledText>
          <a
            href="https://www.eyeandseek.ai/terms-and-policies"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <StyledText
              color="#fff"
              fontSize="1.5rem"
              FsSd="1.5rem"
              fontWeight="bold"
            >
              Terms & Policies
            </StyledText>
          </a>
        </StyledDivFlex>
      </StyledDivFlex>

      {/* web view */}

      <StyledDivBox
        display="none"
        displaySd="flex"
        displayS="flex"
        justifyContentSd="center"
        flexDirectionSd="column"
        // alignItemsSd="center"
      >
        <StyledText
          color="#fff"
          fontSize="1.5rem"
          fontWeight="bold"
          display="none"
          displaySd="flex"
        >
          For inquiries or feedback
        </StyledText>
        <a
          href="mailto: info@eyeandseek.ai"
          style={{ color: "#fff", fontSize: "1.5rem" }}
        >
          info@eyeandseek.ai
        </a>
      </StyledDivBox>

      <StyledDivFlex padding="2rem 0 1rem 0">
        <StyledText Fs="11px" color={theme.colors.secondaryTextColor}>
          Disclaimer: The material provided on this website is for informational
          use only and is not intended for financial, tax or investment advice.
          Eye & Seek LLC and/or its affiliates, assume no liability for any loss
          or damage resulting from one’s reliance on the material provided.
          Please also note that such material is not updated regularly and that
          some of the information may not therefore be current. Consult with
          your own financial professional and tax advisor when making decisions
          regarding your financial situation.
        </StyledText>
      </StyledDivFlex>
    </StyledDivFlex>
  );
};

export default Footer;
