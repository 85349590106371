import { useState } from "react";
// import axios from "../Authorization/Axios";
import { userInfoAPI } from "../Authorization/ServerPath";
import { axiosInstanceWithAuth } from "../Authorization/AxiosConfig";

export const useGetUserInfo = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getUserInfo = async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstanceWithAuth.get(userInfoAPI);
      setIsLoading(false);

      setData(response.data);
    } catch (error) {
      setIsLoading(false);
      setError(error.response);
    }
  };

  return { data, error, isLoading, getUserInfo };
};
