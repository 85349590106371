import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkLoginStatus } from "../Authorization/UserAuthentication";

const isLoggedIn = checkLoginStatus();

export const PublicRouteGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      return navigate("/");
    }
  }, []);

  return <>{children}</>;
};

export const ProtectedRouteGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate("/");
    }
  }, []);

  return <>{children}</>;
};
