import styled from "styled-components";
import {
    largeDevice,
    mediumDevice,
    smallDevice,
    smallestDevice,
} from "../../../constant/mediaQuery";
import { commonCSS } from "./common";

export const StyledButton = styled.button `
  ${commonCSS}
  padding: ${({ padding }) => padding};
  margin-top: ${({ marginTop }) => marginTop};
  background-color: ${({ theme, background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ theme, color }) => color};
  /* cursor: ${({ cursor }) => cursor}; */
  font-style: normal;
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  display: ${({ display }) => display};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  cursor: pointer;
  border: ${({ border }) => border || "none"};
  outline: ${({ outline }) => outline};
  z-index: ${({ zIndex }) => zIndex};
  min-width: ${({ minWidth }) => minWidth};
  &:active {
    outline: none;
  }

  /* opacity: ${({ disabled }) => (disabled === true ? 0.5 : 1)};
  pointer-events: ${({ disabled }) =>
    disabled === true ? "none" : "initial"}; */

  ${largeDevice}
  ${mediumDevice}
  ${smallDevice}
  ${smallestDevice}
`;